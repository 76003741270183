import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BInputGroupAppend } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { AltInput } from "@/core/alt-ui";
import { PasswordBox } from "./password-box";

@Component({
    name: "password-box-component",
    components: { BInputGroupAppend, AltInput, ValidationProvider },
})
export class PasswordBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: PasswordBox;

    private passwordVisible: boolean = false;

    private togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.handler.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        // items.push(<b-form-input
        //     id={this.handler.id}
        //     type={this.handler.type}
        //     class={this.handler.class}
        //     style={this.handler.style}
        //     placeholder={this.handler.placeholder}
        //     disabled={this.handler.disabled}
        //     autocomplete="off"
        //     state={errors.length > 0 ? false : null}
        //     v-model={this.handler.text}
        // />);

        items.push(
            <alt-input
                id={this.handler.id}
                type={this.passwordVisible ? "text" : "password"}
                class={`input-group-merge ${errors.length > 0 ? "is-invalid" : ""} ${this.handler.class}`}
                style={this.handler.style}
                label={this.handler.label}
                placeholder={this.handler.placeholder}
                help={this.handler.help}
                disabled={this.handler.disabled}
                autocomplete="off"
                state={errors.length > 0 ? "error" : null}
                subtext={errors[0]}
                subtext-class="text-danger"
                v-model={this.handler.text}
                scopedSlots={{
                    append: () => this.renderAppend(),
                }}
            />,
        );

        return items;
    }

    private renderAppend(): VNode[] {
        const items: VNode[] = [];

        items.push(
            <b-input-group-append is-text>
                <feather-icon
                    class="cursor-pointer"
                    icon={this.passwordVisible ? "EyeOffIcon" : "EyeIcon"}
                    v-on:click={this.togglePasswordVisibility}
                />
            </b-input-group-append>,
        );

        return items;
    }
}
