export { default } from "./alt-table.vue";

/** Столбцы таблицы. */
export interface IAltTableColumn<T = any> {
    /** Идентификатор. */
    colId: string;

    /** Из какого поля модели брать значение. */
    field?: string;

    /** Заголовок. */
    headerName: string;

    /** Класс заголовка. */
    headerClass?: string;

    /** Чекбокс для выделения всех строк. */
    headerCheckboxSelection?: boolean;

    /** Ширина. */
    width: number;

    /** Минимальная ширина. */
    minWidth?: number;

    /** Возможность сортировки. */
    sortable?: boolean;

    /** Возможность фильтрации */
    filter?: boolean;

    /** Скрыт. */
    hide?: boolean;

    /** Наличие чекбокса для выделения. */
    checkboxSelection?: boolean;

    /** Произвольное значение для ячейки. */
    cellRenderer?: (params: IAltTableCellParams<T>) => string;

    /** Произвольный компонент для отрисовки ячейки. */
    cellRendererFramework?: string;

    /** Произвольные параметры компонента для отрисовки ячейки. */
    cellRendererParams?: (params: IAltTableCellParams<T>) => any;

    /** Произвольное значение для ячейки для экспорта. */
    cellExport?: (params: IAltTableCellParams<T>) => string;
}

/** Параметры ячейки. */
export interface IAltTableCellParams<T = any> {
    /** Вся модель. */
    data: T;

    /** Значение ячейки. */
    value?: any;
}

/**
 * Параметры ячейки для экспорта.
 * @see https://www.ag-grid.com/javascript-data-grid/csv-export/#csvexportparams
 */
export interface IAltTableCellExportParams<TData = any, TContext = any> {
    value: any;
    accumulatedRowIndex?: number;
    node: IRowNode<TData>;
    column: any; //Column;
    type: string;
    // Utility function to parse a value using the column's `colDef.valueParser`
    parseValue: (value: string) => any;
    // Utility function to format a value using the column's `colDef.valueFormatter`
    formatValue: (value: any) => string;
    // The grid api.
    api: any; //GridApi<TData>;
    // Application context as set on `gridOptions.context`.
    context: TContext;
}

/** Данные ячейки. */
export interface IRowNode<TData> {
    id: string;
    data: TData;
}

/** Действие над выделенными строками. */
export interface IAltTableAction<T = any> {
    type?: string;
    name?: string;
    icon?: string;
    click?: (items: T[]) => Promise<void>;
}

/** Настройки действий над выделенными строками. */
export interface IAltTableActionsOptions<T = any> {
    minSelected?: number;
    actions?: IAltTableAction<T>[];
}

export class TableApi {
    private _gridOptions: any = null;

    /** Изменить лимит элементов на странице. */
    public changeLimit(limit: number): void {
        this.notifyChangeLimitListeners(limit);
    }

    private changeLimitListeners = new Set<Function>();
    private notifyChangeLimitListeners(limit: number): void {
        for (const listener of this.changeLimitListeners) {
            listener(limit);
        }
    }
    public addChangeLimitListener(listener: Function): void {
        this.changeLimitListeners.add(listener);
    }

    /** Искать строку. */
    public search(text: string): void {
        this.notifySearchListeners(text);
    }

    private searchListeners = new Set<Function>();
    private notifySearchListeners(text: string): void {
        for (const listener of this.searchListeners) {
            listener(text);
        }
    }
    public addSearchListener(listener: Function): void {
        this.searchListeners.add(listener);
    }

    /** Количество записей на странице по умолчанию. */
    public static DefaultLimit = 10;

    /**
     * Подготовить колонки к отображению.
     * @param defaulColumns Набор колонок по умолчанию.
     * @param dbColumns Набор колонок из базы.
     * @returns Итоговый набор колонок с заполненными свойствами.
     */
    public static prepareColumns(defaulColumns: any[], dbColumns: any[]): any[] {
        // берём дефолтный набор колонок
        // сначала сортируем их в соответсвии с тем, что лежит в базе
        // затем копируем свойства: ширину и видимость из базы

        const sorted = defaulColumns
            .sort((col1: any, col2: any) => {
                for (let i = 0; i < dbColumns.length; ++i) {
                    if (dbColumns[i].colId === col1.colId) {
                        return -1;
                    }
                    if (dbColumns[i].colId === col2.colId) {
                        return 1;
                    }
                }
                return 0;
            })
            .map((column: any) => {
                const dbColumn = dbColumns.find((dbc: any) => dbc.colId === column.colId);
                if (dbColumn) {
                    column.width = column.minWidth
                        ? dbColumn.width > column.minWidth
                            ? dbColumn.width
                            : column.minWidth
                        : dbColumn.width;
                    column.hide = dbColumn.hide;
                }
                return column;
            });

        return sorted;
    }

    public set gridOptions(gridOptions: any) {
        this._gridOptions = gridOptions;
    }

    public get gridOptions(): any {
        return this._gridOptions;
    }
}
