




















import { Vue, Component, Prop } from "vue-property-decorator";
import { Uuid } from "@/utils/uuid";

@Component({
    name: "alt-timeline-item",
})
export default class AltTimelineItem extends Vue {
    @Prop({ type: String, default: "primary" })
    public variant!: string;

    @Prop({ type: String, default: null })
    public title!: string | null;

    @Prop({ type: String, default: null })
    public subtitle!: string | null;

    @Prop({ type: String, default: null })
    public time!: string | null;

    @Prop({ type: String, default: null })
    public icon!: string | null;

    @Prop({ type: Boolean, default: false })
    public fillBorder!: boolean;
}
