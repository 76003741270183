import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { AltPagination } from "@/core/alt-ui";
import { Pagination } from "./pagination";

@Component({
    name: "pagination-component",
    components: { AltPagination },
})
export class PaginationComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Pagination;

    public changePage(page: number): void {
        this.handler.changePage(page);
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <alt-pagination
                    class={this.handler.class}
                    style={this.handler.style}
                    total={this.handler.total}
                    limit={this.handler.limit}
                    v-model={this.handler.page}
                    v-on:change={this.changePage}
                />
            );
        } else {
            return <div />;
        }
    }
}
