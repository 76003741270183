import { EventHandler } from "..";
import { Control } from "./control";
import { Icon } from "./icon";

type TextFieldReceiver<T> = (item: T) => any;

export class DropdownClickEventArgs<T = any> {
    item!: T | null;
    index!: number;
}

export class Dropdown<T = any> extends Control {
    public text = "";
    public variant = "primary";
    public help = "";
    public hideCaret = false;
    public icon: Icon | null = null;
    public items: T[] = [];

    public getComponentName(): string {
        return "DropdownComponent";
    }

    public textField: TextFieldReceiver<T> = (item: T) => item;

    public iconField?: TextFieldReceiver<T> = (item: T) => item;

    public clickItem(item: T): void {
        const index = this.items.findIndex(i => i === item);
        if (index >= 0) {
            this.notifyClickHandlers(item, index);
        }
    }

    private _clickHandlers = new Set<EventHandler<DropdownClickEventArgs<T>>>();
    public addClickHandler(handler: EventHandler<DropdownClickEventArgs<T>>): void {
        this._clickHandlers.add(handler);
    }
    public removeClickHandler(handler: EventHandler<DropdownClickEventArgs<T>>): void {
        this._clickHandlers.delete(handler);
    }
    private notifyClickHandlers(item: T, index: number): void {
        const args: DropdownClickEventArgs<T> = { item, index };
        for (const handler of this._clickHandlers) {
            handler(this, args);
        }
    }
}
