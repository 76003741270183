import { Control } from "./control";
import { EventHandler } from "..";

export class PaginationPageChangedEventArgs {
    page!: number;
    total!: number;
    limit!: number;
}

export class Pagination extends Control {
    public page: number = 1;
    public total: number = 0;
    public limit: number = 10;

    public getComponentName(): string {
        return "PaginationComponent";
    }

    private _changePageHandlers = new Set<EventHandler<PaginationPageChangedEventArgs>>();
    public addChangePageHandler(handler: EventHandler<PaginationPageChangedEventArgs>): void {
        this._changePageHandlers.add(handler);
    }
    public removeChangePageHandler(handler: EventHandler<PaginationPageChangedEventArgs>): void {
        this._changePageHandlers.delete(handler);
    }
    private notifyChangePageHandlers(): void {
        const args: PaginationPageChangedEventArgs = { page: this.page, total: this.total, limit: this.limit };
        for (const handler of this._changePageHandlers) {
            handler(this, args);
        }
    }

    public changePage(page: number): void {
        if (page === this.page) {
            return;
        }

        this.page = page;
        this.notifyChangePageHandlers();
    }
}
