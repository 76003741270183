import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { AltInput } from "@/core/alt-ui";
import { ColorBox } from "./color-box";

@Component({
    name: "color-box-component",
    components: { AltInput },
})
export class ColorBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: ColorBox;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    {this.renderInput()}
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(): VNode[] {
        const items: VNode[] = [];

        items.push(
            <alt-input
                id={this.handler.id}
                type="color"
                label={this.handler.label}
                placeholder={this.handler.placeholder}
                help={this.handler.help}
                disabled={this.handler.disabled}
                v-model={this.handler.value}
            />,
        );

        return items;
    }
}
