import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { AltCollapse, AltCollapseItem } from "@/core/alt-ui";
import { Collapse } from "./collapse";

@Component({
    name: "collapse-component",
    components: {
        AltCollapse,
        AltCollapseItem,
        "control-component": () => import("./control.component"),
    },
})
export class CollapseComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Collapse;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <alt-collapse type="border" class="m-0">
                    <alt-collapse-item
                        id={this.handler.id}
                        title={this.handler.title}
                        is-visible={this.handler.opened}
                        class={`text-xs ${this.handler.class}`}
                        style={this.handler.style}
                    >
                        {this.renderControls()}
                    </alt-collapse-item>
                </alt-collapse>
            );
        } else {
            return <div />;
        }
    }

    private renderControls(): VNode[] {
        const controls: VNode[] = [];
        for (const control of this.handler.controls) {
            controls.push(<control-component handler={control} />);
        }
        return controls;
    }
}
