































import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormCheckbox, BTooltip } from "bootstrap-vue";
import { AltInputState } from ".";

@Component({
    name: "alt-check-box",
    components: { BFormCheckbox, BTooltip },
})
export default class AltCheckBox extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: Boolean, default: false })
    private switch!: boolean;

    @Prop({ type: String })
    private help!: string;

    @Prop({ type: Boolean })
    private disabled!: boolean;

    @Prop({ default: null })
    private state!: AltInputState | null;

    @Prop({ type: String })
    private subtext!: string;

    @Prop({ type: String })
    private subtextClass!: string;

    @Prop({ type: Boolean })
    private value!: boolean;

    public get valueModel(): boolean {
        return this.value;
    }

    public set valueModel(value: boolean) {
        this.$emit("input", value);
    }
}
