























import { Vue, Component } from "vue-property-decorator";
import { VBTooltip } from "bootstrap-vue";
import AltIcon from "@/core/components/alt-icon";

@Component({
    name: "CellRendererActions",
    components: { AltIcon },
    directives: { "b-tooltip": VBTooltip },
})
export default class CellRendererActions extends Vue {
    private params: any;

    private actionsVisible = false;

    public created(): void {
        this.$nextTick(() => {
            // Особенность agGrid: при исчезновении строк данный компонент пересоздаётся ещё раз
            // и сразу удаляется. При этом тултип ищет свой target только в следующем тике.
            // Но в следующей тике компонента уже нет.
            // Идея заглушки: нарисовать кнопки не раньше следующего тика.
            this.actionsVisible = true;
        });
    }

    private get actions(): any {
        return this.params.actions;
    }

    private isVisible(action: any): boolean {
        return action.isVisible(this.params.data);
    }
}
