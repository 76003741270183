import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import { BButton, VBTooltip } from "bootstrap-vue";
import { IAccount, PermissionCommonSection } from "@lib";
import { IEmployee, ITransactionItem, TransactionType } from "@lib";
import { ITableFilter, PermissionType, PermissionRight } from "@lib";
import AltAutoButton from "@/core/components/alt-auto-button";
import { AccountsFilterController } from "../accounts-filter-controller";
import FilterModal from "@core/filters/filter-modal.vue";

@Component({
    name: "accounts-toolbar",
    components: { BButton, AltAutoButton, FilterModal },
    directives: { "b-tooltip": VBTooltip },
})
export default class AccountsToolbar extends Vue {
    @Prop({ type: Array, required: true })
    private accounts!: IAccount[];

    @Prop({ type: Array, required: true })
    private accountsSelected!: IAccount[];

    @Prop({ type: Array, required: true })
    private employees!: IEmployee[];

    @Prop({ type: Array, required: true })
    private transactionItems!: ITransactionItem[];

    @Prop({ type: Array, default: () => [] })
    private filter!: ITableFilter[];

    @Prop({ type: Object, required: true })
    public filterController!: AccountsFilterController;

    @Emit("create")
    private startCreateReceipt(): TransactionType {
        return TransactionType.Income;
    }

    @Emit("create")
    private startCreateDebiting(): TransactionType {
        return TransactionType.Expense;
    }

    private get can(): any {
        const accounts = this.accountsSelected;
        const secure = this.$secure;
        return {
            get create(): boolean {
                for (const account of accounts) {
                    const valid = secure.check(PermissionType.Accounts, account.id, PermissionRight.TransactionsCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
            get readSalary(): boolean {
                return secure.checkCommon(PermissionCommonSection.Salaries, PermissionRight.Read);
            },
        };
    }

    private get hasFilter(): boolean {
        return this.filter.length > 0 && !!this.filter.find(f => f.field !== "account");
    }

    private showFilterModal(): void {
        this.$emit("show-filter");
    }

    private updateFilter(accounts: IAccount[]): void {
        this.filterController.filter.account.set(accounts.map(s => s.id));

        this.$emit("change-filter", this.filterController.tableFilter);
    }

    private async redirectToSalary(): Promise<void> {
        await this.$router.push({ name: "salary" }).catch(() => {});
    }
}
