import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import FlatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { DateRange } from "./date-range";
import { BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import { Formatter } from "@/utils/formatter";

@Component({
    name: "date-range-component",
    components: { BInputGroup, BInputGroupAppend, FlatPickr, ValidationProvider },
})
export class DateRangeComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: DateRange;

    // TODO: locale
    private config = {
        mode: "range",
        locale: Russian,
        altFormat: "d.m.Y",
        altInput: true,
        //dateFormat: "d.m.Y",
        //defaultDate: this.currentValue,
        onChange: this.inputDateRange,
    };

    private inputDateRange(dates: Date[]): void {
        if (dates.length !== 2) {
            return;
        }

        const range = dates.map(dt => Formatter.datetime(dt, { format: "YYYY-MM-DD" }));

        if (range.length === 1) {
            range.push(range[0]);
        }

        // условие, чтобы избежать зацикливания
        if (this.handler.from === range[0] && this.handler.to === range[1]) {
            return;
        }

        this.handler.value = [range[0], range[1]];
    }

    private clear(): void {
        this.handler.value = ["", ""];
    }

    public render(): VNode {
        if (this.handler.visible) {
            return <div class="m-0">{this.renderInput()}</div>;
        } else {
            return <div />;
        }
    }

    private renderInput(): VNode[] {
        const items: VNode[] = [];

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        const input = (
            <flat-pickr
                id={this.handler.id}
                class={this.handler.class}
                style={this.handler.style}
                placeholder={this.handler.placeholder}
                disabled={this.handler.disabled}
                config={this.config}
                value={this.handler.value}
            />
        );

        if (this.handler.value.some(e => e) && !this.handler.disabled) {
            items.push(
                <b-input-group>
                    {input}

                    <b-input-group-append is-text class="cursor-pointer">
                        <feather-icon icon="XIcon" v-on:click={this.clear} />
                    </b-input-group-append>
                </b-input-group>,
            );
        } else {
            items.push(input);
        }

        return items;
    }
}
