import { Control } from "./control";
import { EventHandler } from "..";

export class Badge extends Control {
    public text: string = "";
    public color: string = "";
    public variant: string = "";

    public getComponentName(): string {
        return "BadgeComponent";
    }

    private _clickHandlers = new Set<EventHandler>();
    public addClickHandler(handler: EventHandler): void {
        this._clickHandlers.add(handler);
    }
    public removeClickHandler(handler: EventHandler): void {
        this._clickHandlers.delete(handler);
    }
    private notifyClickHandlers(): void {
        const args = {};
        for (const handler of this._clickHandlers) {
            handler(this, args);
        }
    }

    public click(): void {
        this.notifyClickHandlers();
    }
}
