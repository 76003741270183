/**
 * Alt UI - собственная реализация UI компонентов.
 * Абстракция над UI фреймворком.
 */

/** Состояние Input компонента. */
export enum AltInputState {
    Success = "success",
    Warning = "warning",
    Error = "error",
}

export { default as AltAlert } from "@/core/alt-ui/alt-alert.vue";
export { default as AltBadge } from "@/core/alt-ui/alt-badge.vue";
export { default as AltButton } from "@/core/alt-ui/alt-button.vue";
export { default as AltCheckBox } from "@/core/alt-ui/alt-check-box.vue";
export { default as AltCollapseItem } from "@/core/alt-ui/alt-collapse-item.vue";
export { default as AltCollapse } from "@/core/alt-ui/alt-collapse.vue";
export { default as AltDateTime } from "@/core/alt-ui/alt-date-time.vue";
export { default as AltInput } from "@/core/alt-ui/alt-input.vue";
export { default as AltPagination } from "@/core/alt-ui/alt-pagination.vue";
export { default as AltTextArea } from "@/core/alt-ui/alt-text-area.vue";
export { default as AltTimelineItem } from "@/core/alt-ui/alt-timeline-item.vue";
export { default as AltTimeline } from "@/core/alt-ui/alt-timeline.vue";
