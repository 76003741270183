import { VNode } from "vue";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BSidebar } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Sidebar } from "./sidebar";
import { VisibilityChangedEventArgs } from "..";

import "./sidebar.component.scss";

@Component({
    name: "sidebar-component",
    components: {
        BSidebar,
        ValidationObserver,
        VuePerfectScrollbar,
        ControlComponent: () => import("../controls/control.component"),
    },
})
export class SidebarComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Sidebar;

    @Prop({ type: Boolean, required: false })
    private large!: boolean;

    @Prop({ type: Boolean, required: false })
    private noPadding!: boolean;

    // чтобы событие закрытия сайдбара не дублировалось
    private hideInProgress = false;

    public get id(): string {
        return this.handler.id;
    }

    public get title(): string {
        return this.handler.title;
    }

    public get validatorId(): string {
        return `${this.id}-validator`;
    }

    public visible: boolean = false;

    @Watch("handler")
    private onHandlerChanged(): void {
        this.init();
    }

    public mounted(): void {
        this.init();
    }

    private init(): void {
        this.handler.addVisibleChangedHandler(this.onSidebarVisibleChanged.bind(this));
        this.handler.addValidateHandler(this.onSidebarValidate.bind(this));
    }

    public close(): void {
        if (!this.hideInProgress) {
            this.handler.hide();
        }

        this.hideInProgress = false;
    }

    private onSidebarVisibleChanged(sender: any, e: VisibilityChangedEventArgs): void {
        if (e.event === "hide") {
            this.hideInProgress = true;
        }

        this.visible = e.visible;
    }

    private async onSidebarValidate(sender: any, e: any): Promise<void> {
        e.valid = await (this.$refs[this.validatorId] as any).validate();
    }

    public render(): VNode {
        return (
            <b-sidebar
                id={this.id}
                //class="alt-auto-sidebar"
                bg-variant="white"
                right
                no-header
                backdrop
                shadow
                sidebar-class={this.large ? "sidebar-xl" : ""}
                v-model={this.visible}
                v-on:hidden={this.close}
            >
                <div class="my-1 flex items-center justify-start px-1.5">
                    <feather-icon icon="XIcon" v-on:click={this.close} class="cursor-pointer" />
                    <h4 class="w-full uppercase ml-0.5 mb-0">{this.title}</h4>
                </div>

                <div class="border-top mt-0.5 mb-0"></div>

                <validation-observer ref={this.validatorId}>
                    <vue-perfect-scrollbar
                        class={"scroll-area" + (this.noPadding ? "" : " p-1.5")}
                        settings={{ wheelSpeed: 0.5 }}
                    >
                        {this.renderControls()}
                    </vue-perfect-scrollbar>
                </validation-observer>

                <div class="flex flex-wrap items-center p-1.5" slot="footer">
                    {this.renderFooterControls()}
                </div>
            </b-sidebar>
        );
    }

    private renderControls(): VNode[] {
        const controls: VNode[] = [];
        for (const control of this.handler.controls) {
            controls.push(<control-component handler={control} class="mb-0.75" />);
        }
        return controls;
    }

    private renderFooterControls(): VNode[] {
        const controls: VNode[] = [];
        for (const control of this.handler.footerControls) {
            controls.push(<control-component handler={control} />);
        }
        return controls;
    }
}
