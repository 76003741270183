import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import {
    // BFormSpinbutton,
    BTooltip,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
} from "bootstrap-vue";
import { NumberBox } from "./number-box";
import { StringUtils } from "@/utils/string.utils";

@Component({
    name: "number-box-component",
    components: {
        // BFormSpinbutton,
        BTooltip,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BInputGroupText,
    },
})
export class NumberBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: NumberBox;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    {this.renderInput()}
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(): VNode[] {
        const items: VNode[] = [];

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        items.push(
            // <b-form-spinbutton
            //     id={this.handler.id}
            //     placeholder={this.handler.placeholder}
            //     disabled={this.handler.disabled}
            //     min={this.handler.min}
            //     max={this.handler.max}
            //     step={this.handler.step}
            //     size={this.handler.size}
            //     v-model={this.handler.value}
            // />,

            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text class="cursor-pointer noselect" v-on:click={this.clickMinus}>
                        <feather-icon icon="MinusIcon" class="w-1" />
                    </b-input-group-text>
                </b-input-group-prepend>

                <b-form-input
                    type="number"
                    class="text-center"
                    style="-moz-appearance: textfield;"
                    id={this.handler.id}
                    placeholder={this.handler.placeholder}
                    disabled={this.handler.disabled}
                    min={this.handler.min}
                    max={this.handler.max}
                    step={this.handler.step}
                    size={this.handler.size}
                    v-model={this.handler.value}
                    v-on:keypress={this.keypress}
                    v-on:paste={this.paste}
                    v-on:change={this.changeValue}
                />

                <b-input-group-append>
                    <b-input-group-text class="cursor-pointer noselect" v-on:click={this.clickPlus}>
                        <feather-icon icon="PlusIcon" class="w-1" />
                    </b-input-group-text>
                </b-input-group-append>
            </b-input-group>,
        );

        return items;
    }

    private clickMinus(): void {
        if (this.handler.value === null || this.handler.disabled) {
            return;
        }

        const value = StringUtils.floatStringToNumber(this.handler.value.toString());

        if (value - this.handler.step >= this.handler.min) {
            this.handler.value = value - this.handler.step;
        }

        // если шаг сбился - возвращаем его

        const delta = this.handler.value % this.handler.step;

        if (delta !== 0) {
            this.handler.value -= delta - this.handler.step;
        }
    }

    private clickPlus(): void {
        if (this.handler.value === null || this.handler.disabled) {
            return;
        }

        const value = StringUtils.floatStringToNumber(this.handler.value.toString());

        if (value + this.handler.step <= this.handler.max) {
            this.handler.value = value + this.handler.step;
        }

        // если шаг сбился - возвращаем его

        const delta = this.handler.value % this.handler.step;

        if (delta !== 0) {
            this.handler.value -= delta;
        }
    }

    private keypress(event: any): boolean {
        if (!/^\d+$/.test(event.key)) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    private paste(event: any): boolean {
        const text = event.clipboardData.getData("text");

        if (!/^\d+$/.test(text)) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    private changeValue(): void {
        if (this.handler.value === null || this.handler.disabled) {
            return;
        }

        if (!Number(this.handler.value)) {
            this.handler.value = 0;
            return;
        }

        const value = StringUtils.floatStringToNumber(this.handler.value.toString());

        if (value < this.handler.min) {
            this.handler.value = this.handler.min;
        } else if (value > this.handler.max) {
            this.handler.value = this.handler.max;
        }
    }
}
