import { EventHandler, EventHandlerAsync, ValidateEventArgs, VisibilityChangedEventArgs } from "..";
import { Control } from "../controls";

export abstract class Sidebar<T = undefined, TResult = void> {
    protected _visible: boolean = false;
    protected _hideEvent: Function | null = null;
    protected _errorEvent: Function | null = null;

    public readonly id: string;
    public title: string;

    public constructor(id: string, title = "") {
        this.id = id;
        this.title = title;
    }

    public abstract get controls(): Control[];

    public get footerControls(): Control[] {
        return [];
    }

    //

    public show(data?: T): Promise<TResult> {
        this._visible = true;
        this.notifyVisibleChangedHandlers("show");

        return new Promise((resolve: any, reject: any) => {
            this._hideEvent = (result: TResult) => resolve(result);
            this._errorEvent = (e: Error) => reject(e);
        });
    }

    public hide<TResult>(result?: TResult): void {
        if (this._hideEvent) {
            this._hideEvent(result);
            this._hideEvent = null;
        }

        this._visible = false;
        this.notifyVisibleChangedHandlers("hide");
    }

    private _visibleChangedHandlers = new Set<EventHandler<VisibilityChangedEventArgs>>();
    public addVisibleChangedHandler(handler: EventHandler<VisibilityChangedEventArgs>): void {
        this._visibleChangedHandlers.add(handler);
    }
    public removeVisibleChangedHandler(handler: EventHandler<VisibilityChangedEventArgs>): void {
        this._visibleChangedHandlers.delete(handler);
    }
    private notifyVisibleChangedHandlers(event: string): void {
        const args: VisibilityChangedEventArgs = { visible: this._visible, event: event };
        for (const handler of this._visibleChangedHandlers) {
            handler(this, args);
        }
    }

    //

    public async validate(): Promise<boolean> {
        return await this.notifyValidateHandlers();
    }

    private _validateHandlers = new Set<EventHandlerAsync<ValidateEventArgs>>();
    public addValidateHandler(handler: EventHandlerAsync<ValidateEventArgs>): void {
        this._validateHandlers.add(handler);
    }
    public removeValidateHandler(handler: EventHandlerAsync<ValidateEventArgs>): void {
        this._validateHandlers.delete(handler);
    }
    private async notifyValidateHandlers(): Promise<boolean> {
        const args: ValidateEventArgs = { valid: true };
        for (const handler of this._validateHandlers) {
            await handler(this, args);
            if (!args.valid) {
                return false;
            }
        }
        return true;
    }
}
