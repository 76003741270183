import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BButtonGroup } from "bootstrap-vue";
import { ButtonGroup } from "./button-group";

@Component({
    name: "button-group-component",
    components: {
        BButtonGroup,
        ControlComponent: () => import("../controls/control.component"),
    },
})
export class ButtonGroupComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: ButtonGroup;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <b-button-group size={this.handler.size} class={this.handler.class} style={this.handler.style}>
                    {this.renderButtons()}
                </b-button-group>
            );
        } else {
            return <div />;
        }
    }

    private renderButtons(): VNode[] {
        const controls: VNode[] = [];
        for (const control of this.handler.buttons) {
            controls.push(<control-component handler={control} />);
        }
        return controls;
    }
}
