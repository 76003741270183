import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import Draggable from "vuedraggable";
import { IOrderTypeField, IOrderTypeFieldGroup, OrderTypeFieldGroupType } from "@lib";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { OrderTypeGroups } from "./order-type-groups";
import OrderTypeGroupField from "./order-type-group-field.vue";
import AltIcon from "@/core/components/alt-icon";
import { GroupFieldModal } from "./group-field.modal";
import { FieldModal } from "../field-modal/field.modal";
import { getOrderTypeFieldGroupsDefault } from "@/views/main/settings/settings-lists/settings-lists-ordertypes-defaults";

@Component({
    name: "order-type-groups-component",
    components: { Draggable, BButton, OrderTypeGroupField, ModalComponent, AltIcon },
})
export default class OrderTypeGroupsComponent extends Vue {
    @Prop({ type: Object })
    private handler!: OrderTypeGroups;

    private groups: IOrderTypeFieldGroup[] = [];
    private groupFieldModal: GroupFieldModal;
    private fieldModal: FieldModal;
    private updateKey = 1;

    public constructor() {
        super();

        this.groupFieldModal = new GroupFieldModal();
        this.fieldModal = new FieldModal();
    }

    public get defaultFieldGroupList(): IOrderTypeFieldGroup[] {
        return getOrderTypeFieldGroupsDefault();
    }

    public get itemsId(): string {
        return this.handler.itemsId;
    }

    public mounted(): void {
        this.setupGroups();
    }

    @Watch("itemsId")
    private onItemsLengthChange(val: any): void {
        this.setupGroups();
    }

    private setupGroups(): void {
        this.groups = this.deepSortGroups(this.handler.items);
        this.updateHandler();
    }

    //

    private deepSortGroups(groups: IOrderTypeFieldGroup[]): IOrderTypeFieldGroup[] {
        return this.sortBySequence(
            groups.map(group => ({
                ...group,
                icon: this.getDefaultIcon(group),
                fields: this.sortBySequence(group.fields),
            })),
        );
    }

    private getDefaultIcon(group: IOrderTypeFieldGroup): string | undefined {
        if (group.icon) {
            return group.icon;
        }

        switch (group.type) {
            case OrderTypeFieldGroupType.Client:
                return "user";
            case OrderTypeFieldGroupType.Product:
                return "monitor";
            case OrderTypeFieldGroupType.Info:
                return "info";
            case OrderTypeFieldGroupType.Payment:
                return "bank-card";
        }
    }

    private sortBySequence<T extends { sequence?: number }>(arr: T[]): T[] {
        return arr.sort((a, b) => {
            const aSequence = a.sequence || 9999;
            const bSequence = b.sequence || 9999;

            return aSequence - bSequence;
        });
    }

    //

    private async showAddModal(group: IOrderTypeFieldGroup): Promise<void> {
        const context = {
            fields: this.getAvailableFieldsToAdd(group),
            // для раздела оплат нельзя создавать кастомные поля
            canCreateCustomField: group.type !== OrderTypeFieldGroupType.Payment,
            canSpecifyMacro: true,
            macros: this.handler.macros,
        };

        const fieldAdded = await this.fieldModal.show(context);
        if (!fieldAdded) {
            return;
        }

        const index = group.fields.findIndex(f => f.id === fieldAdded.id);
        if (index >= 0) {
            group.fields[index] = fieldAdded;
        } else {
            group.fields.push(fieldAdded);
        }

        fieldAdded.hidden = false;
        fieldAdded.sequence = this.getMaxFieldSequence(group) + 10;

        group.fields = this.sortBySequence(group.fields);
        this.updateHandler();
    }

    private async showEditModal(group: IOrderTypeFieldGroup, field: IOrderTypeField): Promise<void> {
        const context = {
            field: field,
            // для раздела оплат нельзя создавать кастомные поля
            canCreateCustomField: group.type !== OrderTypeFieldGroupType.Payment,
            canSpecifyMacro: true,
            macros: this.handler.macros,
        };

        const fieldEdited = await this.fieldModal.show(context);
        if (!fieldEdited) {
            return;
        }

        const index = group.fields.findIndex(f => f.id === field.id);
        if (index >= 0) {
            group.fields[index] = fieldEdited;
        }

        //Vue.set(group.fields, fieldIndex, field);
        this.updateHandler();
    }

    private getMaxFieldSequence(group: IOrderTypeFieldGroup): number {
        let max = 0;
        for (const field of group.fields) {
            if (!field.hidden && field.sequence && field.sequence > max) {
                max = field.sequence;
            }
        }
        return max;
    }

    private getAvailableFieldsToAdd(group: IOrderTypeFieldGroup): IOrderTypeField[] {
        const defaultFields = this.defaultFieldGroupList.find(g => g.type === group.type)?.fields ?? [];
        const defaultFieldIds = defaultFields.map(f => f.id);
        const hiddenFields = group.fields.filter(f => f.hidden).filter(f => !defaultFieldIds.includes(f.id));
        const fields = [...defaultFields, ...hiddenFields];

        const visibleFields = group.fields.filter(f => !f.hidden).map(f => f.id) ?? [];
        return fields.filter(f => !visibleFields.includes(f.id));
    }

    //

    private async showGroupUpdateModal(group: IOrderTypeFieldGroup): Promise<void> {
        const result = await this.groupFieldModal.show({ group });
        if (result) {
            group = result;
        }
    }

    private async removeField(group: IOrderTypeFieldGroup, field: IOrderTypeField): Promise<void> {
        const confirm = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить поле: "${field.title}"?`,
            "Удаление поля",
            { acceptText: "Удалить" },
        );

        if (!confirm) {
            return;
        }

        field.hidden = true;

        // const fieldIndex = group.fields.findIndex(f => f.id === field.id);
        // if (field.custom) {
        //     field.hidden = true;
        //     // Vue.set(group.fields, fieldIndex, {
        //     //     ...field,
        //     //     hidden: true,
        //     // });
        // } else {
        //     group.fields.splice(fieldIndex, 1);
        //     //Vue.delete(group.fields, fieldIndex);
        // }

        this.updateHandler();
    }

    private changeFieldPosition(group: IOrderTypeFieldGroup): void {
        group.fields = group.fields.map((field, fieldIndex) => {
            return {
                ...field,
                sequence: (fieldIndex + 1) * 10,
            };
        });

        group.fields = this.sortBySequence(group.fields);
        this.updateHandler();
    }

    private updateHandler(): void {
        this.handler.items = this.groups;
        // console.log(this.handler.items);
        this.updateKey += 1;
    }
}
