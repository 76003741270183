









import { Vue, Component, Prop } from "vue-property-decorator";
import { BDropdownItem, BDropdownDivider } from "bootstrap-vue";
import { IAltTableAction } from ".";

@Component({
    name: "alt-table-actions-item",
    components: { BDropdownItem, BDropdownDivider },
})
export default class AltTableActionsItem extends Vue {
    @Prop({ type: Object, required: true })
    private action!: IAltTableAction;

    @Prop({ type: Object, required: true })
    private gridOptions!: any;

    private get selectedItems(): any[] {
        return this.gridOptions.api.getSelectedRows();
    }
}
