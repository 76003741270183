import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BBadge } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { IGood, ISelectedData } from "@lib";
import { DelayedOperation } from "@/utils/delayed-operation";

@Component({
    name: "alt-search-goods",
    components: {
        BBadge,
        VueAutosuggest,
    },
})
export default class AltSearchGoods extends Vue {
    @Prop({ type: String, required: true })
    public id!: string;

    @Prop({ type: String, default: "" })
    public placeholder!: string;

    /** Функция поиска товара. */
    @Prop({ type: Function, required: true })
    public searchFunc!: (search: string) => Promise<ISelectedData<IGood>>;

    /** Сразу выбрать товар, если он единственный найден. */
    @Prop({ type: Boolean, default: false })
    public selectSingle!: boolean;

    private readonly SearchDelay = 700;

    private search: string = "";
    private foundData: ISelectedData<IGood> = {
        data: [],
        total: 0,
    };

    @Watch("id")
    private onIdChanged(): void {
        this.initValues();
    }

    public mounted(): void {
        this.initValues();
    }

    private initValues(): void {
        this.search = "";
        (document.getElementById("searchInput") as any)?.focus();
    }

    private clear(clearSerch = true): void {
        if (clearSerch) {
            this.search = "";
        }

        this.foundData.data = [];
        this.foundData.total = 0;
    }

    private getSuggestionValue(suggestion: { item: IGood }): string {
        return this.search;
    }

    private selectItemAndHide(suggestion: { item: IGood }): void {
        if (!suggestion) {
            return;
        }

        this.selectItem(suggestion.item);
        this.clear();
    }

    private async changeSearch(): Promise<void> {
        DelayedOperation.invoke("search-good", this.SearchDelay, () => this.searchGoods(this.search));
    }

    private async searchGoods(search: string): Promise<void> {
        try {
            search = search.trim();

            if (search.length < 2) {
                return;
            }

            const result = await this.searchFunc(search);

            if (result.data.length <= 0) {
                this.$alt.toast.warning("Ничего не найдено.");
                this.clear(false);
                return;
            }

            if (result.data.length === 1 && this.selectSingle) {
                this.selectItem(result.data[0]);
                this.clear();
                return;
            }

            this.foundData.data = result.data;
            this.foundData.total = result.total;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private selectItem(item: IGood): void {
        this.$emit("select-item", item);
    }
}
