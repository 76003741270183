import { Vue, Component, Prop } from "vue-property-decorator";
import { BButton, VBTooltip } from "bootstrap-vue";

@Component({
    name: "alt-auto-button",
    components: { BButton },
    directives: { "b-tooltip": VBTooltip },
})
export default class AltAutoButton extends Vue {
    @Prop({ type: String, default: "button" })
    private type!: string;

    @Prop({ type: String, default: "" })
    private text!: string;

    @Prop({ type: String, default: "" })
    private icon!: string;

    @Prop({ type: String, default: "" })
    private tooltip!: string;

    @Prop({ type: String, default: "hover" })
    private tooltipTrigger!: string;

    @Prop({ type: String, default: "bottom" })
    private tooltipPosition!: string;

    @Prop({ type: String, default: "" })
    private tooltipVariant!: string;

    @Prop({ type: String, default: "primary" })
    private variant!: string;

    @Prop({ type: Boolean, default: false })
    private badge!: boolean;

    @Prop({ type: String, default: "badge-danger" })
    private badgeClass!: string;

    private click(args: any): void {
        this.$emit("click", this, args);
    }
}
