







import { Vue, Component, Prop } from "vue-property-decorator";
import { BBadge } from "bootstrap-vue";

@Component({
    name: "alt-badge",
    components: { BBadge },
})
export default class AltBadge extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: String, default: "primary" })
    private variant!: string;

    @Prop({ type: Boolean })
    private disabled!: boolean;

    public click(event: any) {
        this.$emit("click", event);
    }
}
