import { Locale } from "@lib";
import { Localizer } from "@/i18n/localizer";

export abstract class StringUtils {
    /**
     * Склонение слова.
     * @param count Количество.
     * @param forms Формы слова. Массив из 3 элементов (0 - именительный падеж, 1 - родительный, 2 - родительный множественного числа).
     */
    public static decline(count: number, forms: string[]): string {
        if (count < 0) {
            count = -count;
        }
        if (count >= 100) {
            count %= 100;
        }
        if (count >= 20) {
            count %= 10;
        }

        if (count === 1) {
            return forms[0];
        }
        if (count >= 2 && count <= 4) {
            return forms[1];
        }
        return forms[2];
    }

    // TODO: доработать
    public static transliterate(text: string): string {
        const transliterations: Record<string, string> = {
            Ё: "YO",
            Й: "I",
            Ц: "TS",
            У: "U",
            К: "K",
            Е: "E",
            Н: "N",
            Г: "G",
            Ш: "SH",
            Щ: "SCH",
            З: "Z",
            Х: "H",
            Ъ: "'",
            ё: "yo",
            й: "i",
            ц: "ts",
            у: "u",
            к: "k",
            е: "e",
            н: "n",
            г: "g",
            ш: "sh",
            щ: "sch",
            з: "z",
            х: "h",
            ъ: "'",
            Ф: "F",
            Ы: "I",
            В: "V",
            А: "a",
            П: "P",
            Р: "R",
            О: "O",
            Л: "L",
            Д: "D",
            Ж: "ZH",
            Э: "E",
            ф: "f",
            ы: "i",
            в: "v",
            а: "a",
            п: "p",
            р: "r",
            о: "o",
            л: "l",
            д: "d",
            ж: "zh",
            э: "e",
            Я: "Ya",
            Ч: "CH",
            С: "S",
            М: "M",
            И: "I",
            Т: "T",
            Ь: "'",
            Б: "B",
            Ю: "YU",
            я: "ya",
            ч: "ch",
            с: "s",
            м: "m",
            и: "i",
            т: "t",
            ь: "'",
            б: "b",
            ю: "yu",
        };

        return text
            .split("")
            .map(char => transliterations[char] || char)
            .join("");
    }

    /** Перевести число в строку в денежном формате. */
    public static moneyNumberToString(money: number, locale = Locale.RU): string {
        return Localizer.money(money, locale);
    }

    /** Перевести строку в денежном формате в число. */
    public static moneyStringToNumber(moneyString: string): number {
        return this.floatStringToNumber(moneyString);
    }

    /** Перевести строку в десятичном формате в число. */
    public static floatStringToNumber(floatString: string): number {
        floatString = floatString.replace(",", ".");
        const float = parseFloat(floatString);
        return isNaN(float) ? 0.0 : float;
    }

    /** Перевести строку в целочисленном формате в число. */
    public static integerStringToNumber(integerString: string): number {
        const integer = parseInt(integerString);
        return isNaN(integer) ? 0 : integer;
    }

    /** Перевести строку к логическому типу. */
    public static stringToBoolean(input?: string): boolean | undefined {
        try {
            if (!input) {
                return undefined;
            }

            if (typeof input === "string") {
                return JSON.parse(input);
            }

            if (typeof input === "boolean") {
                return input as boolean;
            }
        } catch (e: any) {}
        return false;
    }
}
