






import { Vue, Component, Prop } from "vue-property-decorator";
import { Uuid } from "@/utils/uuid";

enum AltCollapseType {
    Default = "default",
    Border = "border",
    Shadow = "shadow",
    Margin = "margin",
}

@Component({
    name: "alt-collapse",
})
export default class AltCollapse extends Vue {
    @Prop({ type: String, default: AltCollapseType.Default })
    public type!: AltCollapseType;

    // используется в доечерних компонентах
    @Prop({ type: Boolean, default: false })
    public accordion!: boolean;

    // используется в доечерних компонентах
    @Prop({ type: Boolean, default: false })
    public hover!: boolean;

    private readonly collapseVariants = {
        [AltCollapseType.Default]: "alt-collapse-default",
        [AltCollapseType.Border]: "alt-collapse-border",
        [AltCollapseType.Shadow]: "alt-collapse-shadow",
        [AltCollapseType.Margin]: "alt-collapse-margin",
    };

    // используется в доечерних компонентах
    public collapseID = "";

    public get collapseClasses(): string[] {
        const classes = [];
        classes.push(this.collapseVariants[this.type]);
        return classes;
    }

    public created(): void {
        this.collapseID = Uuid.new();
    }
}
