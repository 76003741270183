import { ITableColumn } from "@lib";
import { Button, CheckBox, Control, Label, Panel } from "@core/components/alt-ui/controls";
import { IAltTableCellExportParams, TableApi } from ".";
import { ObjectUtils } from "@/utils/object.utils";
import { Uuid } from "@/utils/uuid";
import { Formatter } from "@/utils/formatter";

export interface IAltTableToolboxContext {
    columns: ITableColumn[];
    export?: IAltTableToolboxExport;
    tableApi: TableApi;
}

export interface IAltTableToolboxExport {
    fileName: string;
    formats?: string[];
}

export class AltTableToolboxControl extends Panel {
    private lbExportHeader!: Label;
    private btnExportCsv!: Button;

    private lbColumnsHeader!: Label;
    private pnlColumns!: Panel;

    private context?: IAltTableToolboxContext;

    private columnsBackup: ITableColumn[] = []; // первоначальные настройки колонок
    private columnsSync: ITableColumn[] = []; // текущие настройки колонок

    public constructor() {
        super();
        this.initializeControls();
    }

    public initialize(context: IAltTableToolboxContext): void {
        this.context = context;

        this.columnsBackup = ObjectUtils.clone(context.columns);
        this.columnsSync = ObjectUtils.clone(context.columns);

        this.initializeControls();
    }

    private initializeControls(): void {
        this.lbExportHeader = new Label();
        this.lbExportHeader.id = "alt-table-toolbox.export-header";
        this.lbExportHeader.class = "font-semibold text-secondary mb-1";
        this.lbExportHeader.style = "font-size: 12px";
        this.lbExportHeader.text = "ЭКСПОРТ";
        this.lbExportHeader.visible =
            this.context?.export?.formats !== undefined && this.context.export.formats.length > 0;

        this.btnExportCsv = new Button();
        this.btnExportCsv.id = "alt-table-toolbox.export-csv";
        this.btnExportCsv.variant = "outline-primary";
        this.btnExportCsv.class = "px-2 py-0.5 mb-2";
        this.btnExportCsv.text = "CSV";
        this.btnExportCsv.help = "Экспортировать текущий вид таблицы в csv";
        this.btnExportCsv.visible =
            this.context?.export?.formats !== undefined && this.context.export.formats.includes("csv");
        this.btnExportCsv.addClickHandler(() => this.exportToCsv());

        this.lbColumnsHeader = new Label();
        this.lbColumnsHeader.id = "alt-table-toolbox.columns-header";
        this.lbColumnsHeader.class = "font-semibold text-secondary mb-1";
        this.lbColumnsHeader.style = "font-size: 12px";
        this.lbColumnsHeader.text = "КОЛОНКИ";

        this.pnlColumns = new Panel();
        this.pnlColumns.id = "alt-table-toolbox.columns";

        if (this.context?.columns) {
            for (const column of this.context.columns) {
                this.pnlColumns.addControl(this.createCheckBox(column));
            }
        }
    }

    private createCheckBox(column: any): CheckBox {
        const checkbox = new CheckBox();
        checkbox.id = Uuid.new();
        checkbox.class = "mb-0.5";
        checkbox.text = column.headerName;
        checkbox.value = !column.hide;

        checkbox.addValueChangedHandler((s, e) => {
            this.columnApi?.setColumnVisible(column.colId, e.value);
            const colSynced = this.columnsSync.find(c => c.colId === column.colId);
            if (colSynced) {
                colSynced.hide = !e.value;
            }
        });

        return checkbox;
    }

    public get controls(): Control[] {
        return [this.lbExportHeader, this.btnExportCsv, this.lbColumnsHeader, this.pnlColumns];
    }

    private get columnApi(): any {
        return this.context?.tableApi?.gridOptions?.columnApi;
    }

    private exportToCsv(): void {
        const datetime = Formatter.now({ format: "YYYYMMDD_HHmmss" });
        const fileName = this.context?.export?.fileName ? `${this.context.export.fileName}_${datetime}` : datetime;

        // https://www.ag-grid.com/javascript-data-grid/csv-export/
        this.context?.tableApi?.gridOptions?.api?.exportDataAsCsv({
            fileName: fileName,
            columnSeparator: ";",
            processHeaderCallback: (params: any): string => {
                return params.column.colDef?.headerName ?? params.column.colId;
            },
            processCellCallback: (params: IAltTableCellExportParams): string => {
                if (params.column.colDef.cellExport) {
                    return params.column.colDef.cellExport({
                        data: params.node.data,
                        value: params.value,
                    });
                }

                if (params.column.colDef.cellRenderer) {
                    return params.column.colDef.cellRenderer({
                        data: params.node.data,
                        value: params.value,
                    });
                }

                return params.value;
            },
        });
    }

    public reset(): void {
        for (const column of this.columnsBackup) {
            const hide = column.hide ?? false;
            this.columnApi?.setColumnVisible(column.colId, !hide);
            const colSynced = this.columnsSync.find(c => c.colId === column.colId);
            if (colSynced) {
                colSynced.hide = hide;
            }
        }
    }

    public getSettings(): { columns: ITableColumn[] } {
        const columnsData = ObjectUtils.clone(this.columnsSync);
        return { columns: columnsData };
    }
}
