import { FieldType, IField } from "@lib";
import { ComboBox, ComboBoxChangedEventArgs } from "@core/components/alt-ui/controls";
import { getAvailableFieldTypes, getFieldTypeText } from "@core/types/fields/field";
import { FieldSettings } from "./field-settings";
import FieldSettingsItemsTextBox from "./field-settings-items/field-settings-items-textbox";
import FieldSettingsItemsDateTime from "./field-settings-items/field-settings-items-datetime";
import FieldSettingsItemsCheckBox from "./field-settings-items/field-settings-items-checkbox";
import FieldSettingsItems from "./field-settings-items/field-settings-items";
import FieldSettingsItemsComboBox from "./field-settings-items/field-settings-items-combobox";
import { Uuid } from "@/utils/uuid";

export class FieldSettingsCustom extends FieldSettings {
    private cbType!: ComboBox<FieldType>;
    private pnlFieldSettingsItems!: FieldSettingsItems;

    protected initializeControls(): void {
        this.cbType = new ComboBox<FieldType>();
        this.cbType.id = "type";
        this.cbType.label = "Тип";
        this.cbType.items = getAvailableFieldTypes();
        this.cbType.textField = getFieldTypeText;
        this.cbType.addChangedHandler(this.onTypeChanged.bind(this));
        this.cbType.selectedItem = FieldType.String;
        this.cbType.class = "mb-0.75";

        this.pnlFieldSettingsItems = new FieldSettingsItemsTextBox(this.context, FieldType.String);

        this.updateControls();
    }

    private updateControls(): void {
        this.clearControls();
        this.addControls([this.cbType, this.pnlFieldSettingsItems]);
    }

    public populateControls(field: IField): void {
        this.cbType.selectedItem = field.type ?? FieldType.String;
        this.cbType.visible = false;
        this.pnlFieldSettingsItems.populateControls(field);
    }

    public getCreatePayload(): IField | null {
        const fieldSettings = this.pnlFieldSettingsItems.getField();
        const customId = this.getCustomId(fieldSettings);

        return {
            ...fieldSettings,
            custom: true,
            customId: customId,
            type: this.cbType.selectedItem ?? FieldType.String,
            // TODO: id должен автоматически генерироваться на стороне сервера
            id: customId,
        } as IField;
    }

    private getCustomId(field: IField): string {
        return field.title?.toLowerCase().replaceAll(".", "_") ?? Uuid.new();
    }

    public getUpdatePayload(): IField | null {
        if (!this.context.field) {
            return null;
        }

        const fieldSettings = this.pnlFieldSettingsItems.getField();

        return {
            ...this.context.field,
            ...fieldSettings,
        } as IField;
    }

    private onTypeChanged(sender: any, e: ComboBoxChangedEventArgs<FieldType>): void {
        switch (e.item) {
            case FieldType.String:
            case FieldType.Text:
            case FieldType.Phone:
                this.pnlFieldSettingsItems = new FieldSettingsItemsTextBox(this.context, e.item);
                break;
            case FieldType.Date:
            case FieldType.Time:
            case FieldType.DateTime:
                this.pnlFieldSettingsItems = new FieldSettingsItemsDateTime(this.context, e.item);
                break;
            case FieldType.Checkbox:
                this.pnlFieldSettingsItems = new FieldSettingsItemsCheckBox(this.context, e.item);
                break;
            case FieldType.Select:
                this.pnlFieldSettingsItems = new FieldSettingsItemsComboBox(this.context, e.item);
                break;
        }

        this.updateControls();
    }

    public validate(): boolean {
        return this.pnlFieldSettingsItems.validate();
    }
}
