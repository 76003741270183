import { FieldType } from "@lib";
import { AppException } from "@/core/exceptions";

export function getFieldTypeText(type: FieldType): string {
    switch (type) {
        case FieldType.String:
            return "Текстовое поле";
        case FieldType.Phone:
            return "Телефон";
        case FieldType.Password:
            return "Пароль";
        case FieldType.Text:
            return "Многострочное поле";
        case FieldType.Number:
            return "Число";
        case FieldType.Date:
            return "Дата";
        case FieldType.Time:
            return "Время";
        case FieldType.DateTime:
            return "Дата и время";
        case FieldType.Color:
            return "Цвет";
        case FieldType.Checkbox:
            return "Галочка";
        case FieldType.Select:
            return "Список";
        case FieldType.MultiSelect:
            return "Мультисписок";
        case FieldType.Composite:
            return "Составное";
        case FieldType.Tags:
            return "Метки";
        case FieldType.Button:
            return "Кнопка";
    }

    throw new AppException(`Unknown field type ${type}`);
}

export function getTextFieldTypes(): FieldType[] {
    return [
        FieldType.String,
        FieldType.Phone,
        FieldType.Text,
        // FieldType.Checkbox,
        // FieldType.Number,
    ];
}

export function getDateTimeFieldTypes(): FieldType[] {
    return [FieldType.Date, FieldType.Time, FieldType.DateTime];
}

export function getMultiselectFieldTypes(): FieldType[] {
    return [
        // FieldType.MultiSelect,
        // FieldType.Tags,
    ];
}

export function getOtherFieldTypes(): FieldType[] {
    return [
        FieldType.Checkbox,
        FieldType.Select,
        // FieldType.Color,
        // FieldType.Button,
        // FieldType.Composite,
    ];
}

export function getAvailableFieldTypes(): FieldType[] {
    return [
        ...getTextFieldTypes(),
        ...getDateTimeFieldTypes(),
        //...getMultiselectFieldTypes(),
        ...getOtherFieldTypes(),
    ];
}
