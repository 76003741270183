






import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "alt-timeline",
})
export default class AltTimeline extends Vue {}
