import { IOrder, IOrderProduct, IPayment, IClient, OrderTypeFieldGroupType } from "@lib";
import { IOrderType, IOrderTypeField, IOrderTypeFieldGroup } from "@lib";
import { FieldType, LegalType } from "@lib";
import { DiscountUtils } from "@/utils/types/discount.utils";
import { getClientTypes } from "../../clients/clients-defaults";

export function getOrderTypesTableColumns(context: any): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 90,
            hide: !context.can.update && !context.can.create && !context.can.delete,
            headerClass: "text-transparent",

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.update,
                            click: (type: IOrderType) => context.openModalUpdate(type),
                        },
                        {
                            id: "copy",
                            description: "Скопировать",
                            icon: "CopyIcon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: () => context.can.create,
                            click: (type: IOrderType) => context.confirmCopy(type),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: () => context.can.delete,
                            click: (type: IOrderType) => context.confirmDelete(type),
                        },
                    ],
                };
            },
        },
        {
            colId: "sequence",
            field: "sequence",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,
        },
        {
            colId: "name",
            field: "name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 250,
            hide: false,
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 400,
            hide: false,
        },
    ];
}

//

export function getOrderTypeFieldGroupsDefault(): IOrderTypeFieldGroup[] {
    const optionsDiscountTypes = DiscountUtils.getOptions();
    return [
        {
            id: "client",
            sequence: 10,
            type: OrderTypeFieldGroupType.Client,
            name: "Клиент",
            fields: [
                {
                    id: "client.type",
                    sequence: 10,
                    standart: "type",
                    type: FieldType.Select,
                    title: "Тип",
                    details1: "select.fixed",
                    details2: JSON.stringify(getClientTypes()),
                    default: getClientTypes()[0].id,
                },
                {
                    id: "client.name",
                    sequence: 20,
                    standart: "name",
                    type: FieldType.String,
                    title: "Имя",
                    validation: {
                        required: true,
                    },
                },
                {
                    id: "client.description",
                    sequence: 30,
                    standart: "description",
                    type: FieldType.Text,
                    title: "Описание",
                },
                {
                    id: "client.taxId",
                    sequence: 40,
                    standart: "taxId",
                    type: FieldType.String,
                    title: "ИНН",
                },
                {
                    id: "client.email",
                    sequence: 50,
                    standart: "email",
                    type: FieldType.String,
                    title: "Почта",
                    validation: {
                        email: true,
                    },
                },
                {
                    id: "client.phone",
                    sequence: 60,
                    standart: "phone",
                    type: FieldType.String,
                    title: "Телефон",
                },
                {
                    id: "client.source",
                    sequence: 70,
                    standart: "source",
                    type: FieldType.Select,
                    title: "Источник",
                    details1: "select.standart",
                    details2: "$(компания.списки.источники)",
                    default: "$(первый)",
                },
                {
                    id: "client.comment",
                    sequence: 80,
                    standart: "comment",
                    type: FieldType.Text,
                    title: "Комментарий",
                },
            ],
        },
        {
            id: "product",
            sequence: 20,
            type: OrderTypeFieldGroupType.Product,
            name: "Устройство",
            fields: [
                {
                    id: "product.type",
                    sequence: 10,
                    standart: "type",
                    type: FieldType.Select,
                    title: "Тип",
                    details1: "select.standart",
                    details2: "$(компания.списки.типыпродуктов)",
                    default: "$(первый)",
                },
                {
                    id: "product.sku",
                    sequence: 20,
                    standart: "sku",
                    type: FieldType.String,
                    title: "Артикул",
                },
                {
                    id: "product.name",
                    sequence: 30,
                    standart: "name",
                    type: FieldType.String,
                    title: "Название",
                    validation: {
                        required: true,
                    },
                },
                {
                    id: "product.description",
                    sequence: 40,
                    standart: "description",
                    type: FieldType.Text,
                    title: "Неисправность",
                    validation: {
                        required: true,
                    },
                },
                {
                    id: "product.brand",
                    sequence: 50,
                    standart: "brand",
                    type: FieldType.String,
                    title: "Бренд",
                },
                {
                    id: "product.model",
                    sequence: 60,
                    standart: "model",
                    type: FieldType.String,
                    title: "Модель",
                },
                {
                    id: "product.serial",
                    sequence: 70,
                    standart: "serial",
                    type: FieldType.String,
                    title: "Серийный номер",
                },
                {
                    id: "product.suite",
                    sequence: 80,
                    standart: "suite",
                    type: FieldType.Text,
                    title: "Комплектация",
                },
                {
                    id: "product.appearance",
                    sequence: 90,
                    standart: "appearance",
                    type: FieldType.Text,
                    title: "Внешний вид",
                    default: "Царапины, потёртости",
                },
                {
                    id: "product.codeblock",
                    sequence: 100,
                    custom: true,
                    customId: "codeblock",
                    type: FieldType.String,
                    title: "Код разблокировки",
                },
            ],
        },
        {
            id: "info",
            sequence: 30,
            type: OrderTypeFieldGroupType.Info,
            name: "Прочее",
            fields: [
                {
                    id: "name",
                    sequence: 10,
                    standart: "name",
                    type: FieldType.String,
                    title: "Название",
                    validation: {
                        required: true,
                    },
                },
                {
                    id: "description",
                    sequence: 20,
                    standart: "description",
                    type: FieldType.Text,
                    title: "Описание",
                },
                {
                    id: "createDate",
                    sequence: 30,
                    standart: "createDate",
                    type: FieldType.DateTime,
                    title: "Дата оформления",
                },
                {
                    id: "deadline",
                    sequence: 40,
                    standart: "deadline",
                    type: FieldType.DateTime,
                    title: "Дата исполнения",
                },
                {
                    id: "discount",
                    sequence: 50,
                    standart: "discount",
                    type: FieldType.Composite,
                    title: "Скидка",
                    elements: [
                        {
                            id: "discount.value",
                            sequence: 1,
                            type: FieldType.String,
                            validation: {
                                money: true,
                                unsigned: true,
                            },
                        },
                        {
                            id: "discount.type",
                            sequence: 2,
                            type: FieldType.Select,
                            details1: "select.fixed",
                            details2: JSON.stringify(optionsDiscountTypes),
                            default: optionsDiscountTypes[0].id,
                        },
                    ],
                },
            ],
        },
        {
            id: "payment",
            sequence: 40,
            type: OrderTypeFieldGroupType.Payment,
            name: "Оплата",
            fields: [
                {
                    id: "payment.value",
                    sequence: 10,
                    standart: "value",
                    type: FieldType.String,
                    title: "Предоплата",
                    default: "0",
                    validation: {
                        money: true,
                        unsigned: true,
                    },
                },
                {
                    id: "payment.account",
                    sequence: 20,
                    standart: "account",
                    type: FieldType.Select,
                    title: "Счёт",
                    details1: "select.standart",
                    details2: "$(компания.счета)",
                    default: "$(первый)",
                },
            ],
        },
    ];
}

export const extractOrderTypeFieldsDefault: any = {
    name: (order: IOrder, value: string) => (order.info.name = value),
    description: (order: IOrder, value: string) => (order.info.description = value),
    createDate: (order: IOrder, value: string) => (order.info.createDate = new Date(value)),
    deadline: (order: IOrder, value: string) => (order.info.deadline = new Date(value)),
    discount: (order: IOrder, value: any) => {
        if (value && value instanceof Array && value.length === 2) {
            order.info.discount = { value: value[0], type: value[1] };
        } else {
            order.info.discount = undefined;
        }
    },
    manager: (order: IOrder, value: string) => (order.manager = value),
    custom: (order: IOrder, value: string, field: IOrderTypeField) => {
        if (!field.details1) {
            return;
        }
        if (!order.custom) {
            order.custom = {};
        }
        order.custom[field.details1] = value;
    },

    "client.type": (client: IClient, value: string) => (client.info.type = value as LegalType),
    "client.name": (client: IClient, value: string) => (client.info.name = value),
    "client.description": (client: IClient, value: string) => (client.info.description = value),
    "client.taxId": (client: IClient, value: string) => (client.info.taxId = value),
    "client.email": (client: IClient, value: string) => {
        if (!client.info.contacts) {
            client.info.contacts = { email: "" };
        }
        client.info.contacts.email = value;
    },
    "client.phone": (client: IClient, value: string) => {
        if (!client.info.contacts) {
            client.info.contacts = { email: "" };
        }
        client.info.contacts.phone = value;
    },
    "client.source": (client: IClient, value: string) => (client.info.source = value),
    "client.comment": (client: IClient, value: string) => (client.comment = value),

    "product.type": (product: IOrderProduct, value: string) => (product.info.type = value),
    "product.sku": (product: IOrderProduct, value: string) => (product.info.sku = value),
    "product.name": (product: IOrderProduct, value: string) => (product.info.name = value),
    "product.description": (product: IOrderProduct, value: string) => (product.info.description = value),
    "product.brand": (product: IOrderProduct, value: string) => (product.info.brand = value),
    "product.model": (product: IOrderProduct, value: string) => (product.info.model = value),
    "product.serial": (product: IOrderProduct, value: string) => (product.info.serial = value),
    "product.suite": (product: IOrderProduct, value: string) => (product.info.suite = value),
    "product.appearance": (product: IOrderProduct, value: string) => (product.info.appearance = value),
    "product.parameter": (product: IOrderProduct, value: string, field: IOrderTypeField) => {
        if (!field.details1) {
            return;
        }
        if (!product.info.parameters) {
            product.info.parameters = {};
        }
        product.info.parameters[field.details1] = value;
    },

    "payment.value": (payment: IPayment, value: string) => {
        const moneyStr = value.trim().replace(",", ".");
        if (moneyStr.length === 0) {
            return;
        }
        const money = parseFloat(moneyStr);
        if (money === 0) {
            return;
        }
        payment.value = money;
    },
    "payment.account": (payment: IPayment, value: string) => {
        payment.account = value;
    },
};
