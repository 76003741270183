




































import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormInput, BInputGroup, BTooltip } from "bootstrap-vue";
import { AltInputState } from ".";

@Component({
    name: "alt-input",
    components: { BFormInput, BInputGroup, BTooltip },
})
export default class AltInput extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: String, default: "text" })
    private type!: string;

    @Prop({ type: String })
    private label!: string;

    @Prop({ type: String })
    private placeholder!: string;

    @Prop({ type: String })
    private help!: string;

    @Prop({ type: Boolean })
    private disabled!: boolean;

    @Prop({ default: null })
    private state!: AltInputState | null;

    @Prop({ type: String })
    private subtext!: string;

    @Prop({ type: String })
    private subtextClass!: string;

    @Prop({ type: String })
    private value!: string;

    public get text(): string {
        return this.value;
    }

    public set text(text: string) {
        this.$emit("input", text);
    }
}
