





















import { Vue, Component, Prop } from "vue-property-decorator";
import { BDropdown, BDropdownItem, BDropdownDivider } from "bootstrap-vue";
import AltTableActionsItem from "./alt-table-actions-item.vue";
import { IAltTableActionsOptions } from ".";

const DefaultActionsOptions = {
    minSelected: 2,
    actions: [],
};

@Component({
    name: "alt-table-actions",
    components: { BDropdown, BDropdownItem, BDropdownDivider, AltTableActionsItem },
})
export default class AltTableActions extends Vue {
    @Prop({ type: Object, default: () => {} })
    private actionsOptions!: IAltTableActionsOptions;

    @Prop({ type: Object, required: true })
    private gridOptions!: any;

    private get options(): IAltTableActionsOptions {
        return { ...DefaultActionsOptions, ...this.actionsOptions };
    }

    private get selectedItems(): any[] {
        return this.gridOptions.api.getSelectedRows();
    }

    private deselect(): void {
        this.gridOptions.api.deselectAll();
    }
}
