import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BTooltip } from "bootstrap-vue";
import { AltButton } from "@/core/alt-ui";
import { Button } from "./button";
import { IconComponent } from "./icon.component";

@Component({
    name: "button-component",
    components: { BTooltip, AltButton, IconComponent },
})
export class ButtonComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Button;

    public click(): void {
        this.handler.click();
    }

    public render(): VNode {
        if (this.handler.visible) {
            if (this.handler.help && this.handler.help.length > 0) {
                return (
                    <div>
                        {this.renderButton()}

                        <b-tooltip target={this.handler.id} triggers="hover" placement="bottom">
                            {this.handler.help}
                        </b-tooltip>
                    </div>
                );
            }

            return this.renderButton();
        } else {
            return <div />;
        }
    }

    private renderButton(): VNode {
        return (
            <alt-button
                id={this.handler.id}
                variant={this.handler.variant}
                class={this.handler.class}
                style={this.handler.style}
                disabled={this.handler.disabled}
                v-on:click={this.click}
            >
                {this.renderContent()}
            </alt-button>
        );
    }

    private renderContent(): VNode[] {
        const content: VNode[] = [];
        if (this.handler.icon) {
            const cl = this.handler.text.length > 0 ? "mr-0.5" : "";
            content.push(<icon-component class={cl} handler={this.handler.icon} />);
        }
        content.push(<span>{this.handler.text}</span>);
        return content;
    }
}
