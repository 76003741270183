import { Control } from "../controls";
import { Modal } from "../modal/modal";

export abstract class Modal2<T = undefined, TResult = void> extends Modal<T, TResult> {
    public scrollable = true;

    protected _visible: boolean = false;
    protected _resultEvent: Function | null = null;
    protected _errorEvent: Function | null = null;

    public constructor(id: string, title = "") {
        super(id, title);
    }

    public get footer(): Control | null {
        return null;
    }
}
