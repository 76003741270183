import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import VueSelect from "vue-select";
import { BTooltip } from "bootstrap-vue";
import AltIcon from "@/core/components/alt-icon";
import { Select } from "./select";
import { IconPackage } from "./icon";

@Component({
    name: "select-component",
    components: { VueSelect, BTooltip, AltIcon },
})
export class SelectComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Select;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                //<div class="form-label-group m-0">
                <div>{this.renderParts()}</div>
            );
        } else {
            return <div />;
        }
    }

    private renderParts(): VNode[] {
        const items: VNode[] = [];

        if (this.handler.label && this.handler.label.length > 0) {
            items.push(
                <label class="text-nowrap px-0.5" for={this.handler.id}>
                    {this.handler.label}
                </label>,
            );
        }

        if (this.handler.help && this.handler.help.length > 0) {
            items.push(
                <feather-icon
                    id={this.handler.id + "-help"}
                    icon="HelpCircleIcon"
                    class="w-1 cursor-pointer text-grey hover:text-primary"
                />,
            );

            items.push(
                <b-tooltip target={this.handler.id + "-help"} triggers="hover" placement="right">
                    {this.handler.help}
                </b-tooltip>,
            );
        }

        if (this.handler.search && typeof this.handler.search === "function") {
            items.push(
                <vue-select
                    class={this.handler.class}
                    style={this.handler.style}
                    disabled={this.handler.disabled}
                    clearable={this.handler.clearable}
                    get-option-label={this.handler.textField}
                    options={this.handler.items}
                    filterable={false} // disable the client side filtering
                    v-on:search={this.handler.search}
                    v-model={this.handler.selectedItem}
                    appendToBody
                    scopedSlots={{
                        "no-options": () => "Нет подходящих вариантов",
                        option: (option: any) => this.renderOption(option),
                    }}
                />,
            );
        } else {
            items.push(
                <vue-select
                    class={this.handler.class}
                    style={this.handler.style}
                    disabled={this.handler.disabled}
                    clearable={this.handler.clearable}
                    get-option-label={this.handler.textField}
                    options={this.handler.items}
                    v-model={this.handler.selectedItem}
                    appendToBody
                    scopedSlots={{
                        "no-options": () => "Нет подходящих вариантов",
                        option: (option: any) => this.renderOption(option),
                        "selected-option": (option: any) => this.renderSelectedOption(option),
                    }}
                />,
            );
        }

        return items;
    }

    private renderOption(option: any): VNode[] {
        const items: VNode[] = [];

        // TODO: primitive types wrap to object { label: string }
        // https://vue-select.org/guide/options.html#options-prop

        if (this.handler.iconField && this.handler.iconPackage === IconPackage.Alt) {
            const icon = this.handler.iconField ? this.handler.iconField(option) : null;

            if (icon) {
                items.push(<alt-icon icon={icon} class="w-1 mr-0.5" />);
            }
        } else if (this.handler.iconField && this.handler.iconPackage === IconPackage.Feater) {
            const icon = this.handler.iconField ? this.handler.iconField(option) : null;

            if (icon) {
                items.push(<feather-icon icon={this.handler.iconField} class="w-1 mr-0.5" />);
            }
        }

        items.push(<span>{this.handler.textField ? this.handler.textField(option) : ""}</span>);

        if (this.handler.descriptionField) {
            items.push(<br />);
            items.push(<small class="text-xs opacity-75 truncate">{this.handler.descriptionField(option)}</small>);
        }

        return items;
    }

    private renderSelectedOption(option: any): VNode[] {
        const items: VNode[] = [];

        if (this.handler.icon && this.handler.iconPackage === IconPackage.Alt) {
            items.push(<alt-icon icon={this.handler.icon} class="w-1 mr-0.5" />);
        } else if (this.handler.icon && this.handler.iconPackage === IconPackage.Feater) {
            items.push(<feather-icon icon={this.handler.icon} class="w-1 mr-0.5" />);
        }

        items.push(<span>{this.handler.textField ? this.handler.textField(option) : ""}</span>);
        return items;
    }
}
