import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { AltTextArea } from "@/core/alt-ui";
import { TextArea } from "./text-area";

@Component({
    name: "text-area-component",
    components: { AltTextArea, ValidationProvider },
})
export class TextAreaComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: TextArea;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.handler.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderTextArea(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderTextArea(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        items.push(
            <alt-text-area
                id={this.handler.id}
                label={this.handler.label}
                placeholder={this.handler.placeholder}
                help={this.handler.help}
                disabled={this.handler.disabled}
                rows={this.handler.rows}
                rows-max={this.handler.rowsMax}
                state={errors.length > 0 ? "error" : null}
                subtext={errors[0]}
                subtext-class="text-danger"
                v-model={this.handler.text}
            />,
        );

        return items;
    }
}
