import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { AltInput } from "@/core/alt-ui";
import { TextBox } from "./text-box";

@Component({
    name: "text-box-component",
    components: { AltInput, ValidationProvider },
})
export class TextBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: TextBox;

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.handler.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        items.push(
            <alt-input
                id={this.handler.id}
                type={this.handler.type}
                label={this.handler.label}
                placeholder={this.handler.placeholder}
                help={this.handler.help}
                disabled={this.handler.disabled}
                autocomplete="off"
                state={errors.length > 0 ? "error" : null}
                subtext={errors[0]}
                subtext-class="text-danger"
                v-model={this.handler.text}
            />,
        );

        return items;
    }
}
