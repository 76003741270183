import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationProvider, Validator } from "vee-validate";
import { AltInput } from "@/core/alt-ui";
import validators from "@/views/main/settings/settings-company/modals/validators";
import { PhoneBox } from "./phone-box";
import { Formatter } from "@/utils/formatter";

const PhoneRuleName = "phone-number";
Validator.extend(PhoneRuleName, validators.validatePhoneNumber);

@Component({
    name: "phone-box-component",
    components: { AltInput, ValidationProvider },
})
export class PhoneBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: PhoneBox;

    public get text(): string {
        return Formatter.phone(this.handler.text);
    }

    public set text(text: string) {
        this.handler.text = text.replace(/\s/g, "");
    }

    public get validation(): string | null {
        return this.handler.validation ? `${this.handler.validation}|${PhoneRuleName}` : PhoneRuleName;
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        items.push(
            <alt-input
                id={this.handler.id}
                type={this.handler.type}
                label={this.handler.label}
                placeholder={this.handler.placeholder}
                help={this.handler.help}
                disabled={this.handler.disabled}
                autocomplete="off"
                state={errors.length > 0 ? "error" : null}
                subtext={errors[0]}
                subtext-class="text-danger"
                v-model={this.text}
            />,
        );

        return items;
    }
}
