import { IAccount, IOrder, ISale, IGood, IUser, ISalary, IGoodRegistration } from "@lib";
import { ITransaction, ITransactionItem } from "@lib";
import router from "@/router/router";
import * as filters from "@/filters";

export function getDefaultTableColumns(context: any, items: ITransactionItem[]): any[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 75 + (context.can.delete ? 25 : 0),
            minWidth: 75 + (context.can.delete ? 25 : 0),
            hide: false,
            headerClass: "text-transparent",
            headerCheckboxSelection: true,
            checkboxSelection: true,

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: (transaction: ITransaction) => context.can.delete(transaction),
                            click: (transaction: ITransaction) => context.confirmDelete(transaction),
                        },
                    ],
                };
            },
        },
        // {
        //     colId: "checkboxes",
        //     headerName: "#",
        //     width: 50,
        //     hide: false,
        //     filter: false,
        //     checkboxSelection: true,
        //     headerCheckboxSelectionFilteredOnly: true,
        //     headerCheckboxSelection: true
        // },
        {
            colId: "account",
            field: "acoount",
            headerName: "Счёт",
            sortable: true,
            filter: true,
            width: 160,
            hide: true,
            cellRendererFramework: "CellRendererChip",

            cellRendererParams: (params: any) => {
                return {
                    name: params.data.accountRef?.info.name,
                };
            },
        },
        {
            colId: "datetime",
            field: "createdAt",
            headerName: "Дата и время",
            sortable: true,
            filter: true,
            width: 165,
            hide: false,

            cellRenderer: function (params: any) {
                return filters.datetime.datetime(params.value, "L LT");
            },
        },
        {
            colId: "item",
            field: "item",
            headerName: "Статья",
            sortable: true,
            filter: true,
            width: 200,
            hide: false,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: any) => {
                const item = items.find(item => item.id === params.value);
                return {
                    name: item ? item.name : "-",
                };
            },
        },
        {
            colId: "description",
            field: "description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 500,
            hide: false,

            cellRendererFramework: "CellRendererDynamic",
            cellRendererParams: (params: any) => {
                const transaction = params.data as ITransaction;

                const elements: any[] = [];
                elements.push({ type: "text", text: params.value });

                if (transaction.meta?.employee) {
                    const employee = transaction.meta.employee as IUser;
                    elements.push({ type: "text", text: ` (${employee.info.name})` });
                }

                if (transaction.meta?.order) {
                    const order = transaction.meta.order as any as IOrder;

                    elements.push({ type: "text", text: " (" });
                    elements.push({
                        type: "link",
                        class: "hover:underline",
                        text: `заявка #${order.number}`,
                        meta: {
                            click: () => router.push({ name: "orders", query: { id: order.id } }),
                        },
                    });
                    elements.push({ type: "text", text: ")" });
                }

                if (transaction.meta?.sale) {
                    const sale = transaction.meta.sale as any as ISale;

                    elements.push({ type: "text", text: " (" });
                    elements.push({
                        type: "link",
                        class: "hover:underline",
                        text: `продажа #${sale.number}`,
                        meta: {
                            click: () => router.push({ name: "sales", query: { id: sale.id } }),
                        },
                    });
                    elements.push({ type: "text", text: ")" });
                }

                if (transaction.meta?.goodRegistration) {
                    const goodRegistration = transaction.meta.goodRegistration as any as IGoodRegistration;

                    elements.push({ type: "text", text: " (" });
                    elements.push({
                        type: "link",
                        class: "hover:underline",
                        text: `оприходование #${goodRegistration.number}`,
                        meta: {
                            click: () => router.push({ name: "goodRegistrations", query: { id: goodRegistration.id } }),
                        },
                    });
                    elements.push({ type: "text", text: ")" });
                }

                if (transaction.meta?.good) {
                    const good = transaction.meta.good as any as IGood;

                    elements.push({ type: "text", text: " (" });
                    elements.push({
                        type: "link",
                        class: "hover:underline",
                        text: good.info.name,
                        meta: {
                            click: () => router.push({ name: "stores", query: { id: good.id } }),
                        },
                    });
                    elements.push({ type: "text", text: ")" });
                }

                if (transaction.meta?.salary) {
                    const salary = transaction.meta.salary as any as ISalary;
                    const employee = salary.employeeRef;

                    if (employee?.info?.name) {
                        elements.push({
                            type: "text",
                            text: ` (выплата #${salary.number} сотруднику ${employee.info.name})`,
                        });
                    } else {
                        elements.push({ type: "text", text: ` (выплата #${salary.number})` });
                    }
                }

                return { elements };
            },
        },
        {
            colId: "value",
            field: "value",
            headerName: "Сумма",
            sortable: true,
            filter: true,
            width: 175,
            hide: false,
            cellRendererFramework: "CellRendererMoney",

            cellRendererParams: (params: any) => {
                const transaction = params.data as ITransaction;
                const currency = transaction?.accountRef?.info?.currency;
                const locale = transaction?.accountRef?.info?.locale;
                return {
                    currency: currency,
                    locale: locale,
                    class: transaction.value > 0 ? "text-success" : transaction.value < 0 ? "text-danger" : undefined,
                };
            },
        },
        {
            colId: "user",
            field: "user",
            headerName: "Сотрудник",
            sortable: true,
            filter: true,
            width: 220,
            hide: false,

            cellRendererFramework: "CellRendererAvatar",
            cellRendererParams: (params: any) => {
                const user = params.data.userRef;
                return {
                    showAvatar: true,
                    avatar: user?.info.avatar ?? "",
                    name: user?.info.name ?? "",
                };
            },
        },
    ];
}

//

export function getDefaultTableActions(context: any): any[] {
    return [
        {
            name: "Удалить всё",
            icon: "Trash2Icon",
            click: (transactions: ITransaction[]) => context.confirmDeleteMany(transactions),
        },
    ];
}
