import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import { BButton, BFormInput, BDropdown, BDropdownItem, BPagination, VBTooltip } from "bootstrap-vue";
import AltTableLimits from "./alt-table-limits.vue";
import AltTableActions from "./alt-table-actions.vue";
import { IAltTableToolboxExport, AltTableToolbox } from "./alt-table-toolbox";
import { IAltTableActionsOptions } from ".";
import { DelayedOperation } from "@/utils/delayed-operation";

// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import CellRendererAvatar from "./cell-renderer/CellRendererAvatar.vue";
import CellRendererChip from "./cell-renderer/CellRendererChip.vue";
import CellRendererChips from "./cell-renderer/CellRendererChips.vue";
import CellRendererDynamic from "./cell-renderer/CellRendererDynamic.vue";
import CellRendererMoney from "./cell-renderer/CellRendererMoney.vue";
import CellRendererVerified from "./cell-renderer/CellRendererVerified.vue";
import CellRendererTags from "./cell-renderer/CellRendererTags.vue";
import { TableApi } from ".";
import { SidebarComponent } from "@core/components/alt-ui/sidebar/sidebar.component";
import { ITableColumn } from "@lib";

export interface ITableOptions {
    /** Доступна ли панель инструментов. */
    toolbox?: boolean;
    export?: IAltTableToolboxExport;
    doubleClick?: (item: any) => void;
}

const DefaultTableOptions: ITableOptions = {
    //
};

@Component({
    name: "alt-table",
    components: {
        BButton,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BPagination,

        AgGridVue,
        AltTableLimits,
        AltTableActions,

        // Cell Renderer
        CellRendererActions,
        CellRendererAvatar,
        CellRendererChip,
        CellRendererChips,
        CellRendererDynamic,
        CellRendererMoney,
        CellRendererVerified,
        CellRendererTags,

        SidebarComponent,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class AltTable extends Vue {
    @Prop({ type: Array, required: true })
    private columns!: any[];

    @Prop({ type: Array, default: () => [] })
    private columnsSort!: any[];

    @Prop({ type: Array, required: true })
    private items!: any[];

    @Prop({ type: Number, required: false })
    private itemsTotal!: number | undefined;

    @Prop({ type: Number, default: 10 })
    private itemsLimit!: number;

    @Prop({ type: Boolean, default: false })
    private pagination!: boolean;

    @Prop({ type: Boolean, default: false })
    private limits!: boolean;

    @Prop({ type: Boolean, default: false })
    private search!: boolean;

    @Prop({ type: Object, default: () => {} })
    private options!: ITableOptions;

    @Prop({ type: Boolean, default: false })
    private actions!: boolean;

    @Prop({ type: Object, default: () => {} })
    private actionsOptions!: IAltTableActionsOptions;

    @Prop({ type: Object, default: () => {} })
    private cellRenderers!: object;

    /** Объект для общения дочерних компонентов с родителем
     *  https://www.ag-grid.com/vue-components/ */
    // @Prop({ type: Object, default: () => {} })
    // private context!: object;

    @PropSync("update", { type: Boolean, default: false })
    private updateSync!: boolean;

    @Prop({ type: Object, default: () => new TableApi() })
    private tableApi!: TableApi;

    private toolboxSidebar: AltTableToolbox;

    private static readonly DelaySearch = 750;
    private static readonly DelayChangeColumns = 3000;

    private updateKey: number = 1;

    private toolboxShowSync: boolean = false;
    private columnsDef = this.columns;

    private searchString = "";

    private limitsOptions: number[] = [10, 25, 50, 100];

    private pageCurrent: number = 1;
    private selectedRows: any[] = [];

    // AgGrid
    private gridApi: any = null;
    private gridOptions: any = {
        // скрыть ворнинги, что в динамических колонках есть наши собственные поля
        suppressPropertyNamesCheck: true,
    };
    private defaultColDef: any = {
        sortable: false,
        resizable: true,
        editable: false,
        suppressMenu: true,
    };

    // https://www.ag-grid.com/javascript-grid-internationalisation/
    private localeText: any = {
        noRowsToShow: "Нет записей",
    };

    private get isColumnsWidthBiggerThanViewport(): boolean {
        const width = this.columns.reduce((result, column) => {
            if (!column.hide) {
                result += column.width;
                return result;
            }

            return result;
        }, 0);

        return width > Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    }

    private get components(): any {
        // Cell Renderer Components
        const standart = {
            CellRendererActions,
            CellRendererAvatar,
            CellRendererMoney,
            CellRendererVerified,
        };
        return Object.assign({}, standart, this.cellRenderers);
    }

    public constructor() {
        super();

        this.toolboxSidebar = new AltTableToolbox();
    }

    private get itemsData(): any[] {
        return this.items;
    }

    private get total(): number {
        return this.itemsTotal ?? this.items.length;
    }

    private get limit(): number {
        return this.itemsLimit;
    }

    private get offset(): number {
        return (this.pageCurrent - 1) * this.limit;
    }

    private get pagesTotal(): number {
        return Math.ceil(this.total / this.limit);
    }

    @Watch("items", { deep: true })
    private onItemsChanged(): void {
        this.refreshTable();
    }

    @Watch("columns", { deep: true })
    private onColumnsChanged(): void {
        this.columnsDef = this.columns;
        this.refreshTable();
    }

    @Watch("columnsSort", { deep: true })
    private onColumnsSortChanged(): void {
        this.gridApi.setSortModel(this.columnsSort);
    }

    @Watch("update")
    private onUpdateChanged(forceUpdate: boolean): void {
        if (forceUpdate) {
            this.gridApi.deselectAll();
            this.updateKey += 1;
            this.updateSync = false;
        }
    }

    public mounted(): void {
        this.initTableApi();

        this.gridApi = this.gridOptions.api;
        //const gridColumnApi = this.gridOptions.columnApi;

        // this.gridOptions.context = this.context;

        this.gridOptions.onCellEditingStopped = (event: any) => {
            this.$emit("cell-edit", event);
        };

        this.refreshTable();
    }

    private initTableApi(): void {
        if (this.tableApi) {
            if (this.limits) {
                this.tableApi.addChangeLimitListener((limit: number) => {
                    this.changeLimit(limit);
                });
            }

            if (this.search) {
                this.tableApi.addSearchListener((text: string) => {
                    this.searchString = text;
                    this.startSearchNoDelay(text);
                });
            }

            this.tableApi.gridOptions = this.gridOptions;
        }
    }

    private refreshTable(): void {
        this.gridApi.deselectAll();
        // this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(this.columnsDef);
        this.gridApi.setSortModel(this.columnsSort);
    }

    private changeSelection(event: any): void {
        this.selectedRows = event.api.getSelectedRows();
    }

    private startSearch(search: string): void {
        DelayedOperation.invoke("search", AltTable.DelaySearch, () => {
            this.startSearchNoDelay(search);
        });
    }

    private startSearchNoDelay(search: string): void {
        this.gridApi.deselectAll();
        this.pageCurrent = 1;
        this.$emit("search", search);
    }

    private changePage(page: number): void {
        if (page === this.pageCurrent) {
            return;
        }

        // не генерировать событие, если нет данных
        if (0 === this.items.length) {
            return;
        }

        this.gridApi.deselectAll();
        this.pageCurrent = page;
        this.$emit("page-changed", this.pageCurrent, this.offset, this.limit);
    }

    private changeLimit(limit: number): void {
        if (limit === this.itemsLimit) {
            return;
        }

        this.gridApi.deselectAll();
        this.pageCurrent = 1;
        this.$emit("limit-changed", limit, this.pageCurrent);
    }

    private changeSort(event: any): void {
        const sortState = event.api.getSortModel();

        // если сортировка не поменялась, событие не генерируем
        // это обход зацикливания при изменении сортировки программно
        if (JSON.stringify(sortState) === JSON.stringify(this.columnsSort)) {
            return;
        }

        this.gridApi.deselectAll();
        this.$emit("sort", sortState);
    }

    private resizeColumn(event: any): void {
        if (event.finished) {
            const column = this.columnsDef.find((col: any) => col.colId === event.column.colId);
            if (column) {
                column.width = event.column.actualWidth;
            }

            DelayedOperation.invoke("column", AltTable.DelayChangeColumns, () => {
                this.gridApi.deselectAll();
                this.$emit("columns-changed", this.columnsDef);
            });
        }
    }

    private moveColumn(event: any): void {
        if (event.source !== "uiColumnDragged") {
            return;
        }

        const columnsCurrent = this.gridOptions.columnApi.getAllGridColumns();
        this.columnsDef = this.columnsDef.sort((col1: any, col2: any) => {
            for (let i = 0; i < columnsCurrent.length; ++i) {
                if (columnsCurrent[i].colId === col1.colId) {
                    return -1;
                }
                if (columnsCurrent[i].colId === col2.colId) {
                    return 1;
                }
            }
            return 0;
        });

        DelayedOperation.invoke("column", AltTable.DelayChangeColumns, () => {
            this.gridApi.deselectAll();
            this.$emit("columns-changed", this.columnsDef);
        });
    }

    private saveToolbox(columns: ITableColumn[]): void {
        this.columnsDef = columns;
        DelayedOperation.reset("column");
        this.gridApi.deselectAll();
        this.$emit("columns-changed", this.columnsDef);
    }

    private async showToolbox(): Promise<void> {
        const settings = await this.toolboxSidebar.show({
            columns: this.columnsDef,
            export: this.options?.export,
            tableApi: this.tableApi,
        });

        if (settings) {
            this.saveToolbox(settings.columns);
        }
    }

    private doubleClickOnRow(event: any): void {
        if (this.options?.doubleClick) {
            this.options.doubleClick(event.data);
        }
    }
}
