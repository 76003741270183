






























import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormTextarea, BTooltip } from "bootstrap-vue";
import { AltInputState } from ".";

@Component({
    name: "alt-text-area",
    components: { BFormTextarea, BTooltip },
})
export default class AltTextArea extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: String })
    private label!: string;

    @Prop({ type: String })
    private placeholder!: string;

    @Prop({ type: String })
    private help!: string;

    @Prop({ type: Boolean })
    private disabled!: boolean;

    @Prop({ default: 3 })
    private rows!: number;

    @Prop({ default: null })
    private rowsMax!: number | null;

    @Prop({ default: null })
    private state!: AltInputState | null;

    @Prop({ type: String })
    private subtext!: string;

    @Prop({ type: String })
    private subtextClass!: string;

    @Prop({ type: String })
    private value!: string;

    public get text(): string {
        return this.value;
    }

    public set text(text: string) {
        this.$emit("input", text);
    }
}
