import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { AltBadge } from "@/core/alt-ui";
import { Badge } from "./badge";

@Component({
    name: "badge-component",
    components: { AltBadge },
})
export class BadgeComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Badge;

    public click(): void {
        this.handler.click();
    }

    public render(): VNode {
        if (this.handler.visible) {
            let style = this.handler.style;

            if (this.handler.color.length > 0) {
                style += ` background-color: ${this.handler.color}`;
            }

            return (
                <alt-badge
                    variant={this.handler.variant}
                    class={this.handler.class}
                    style={style}
                    v-on:click={this.click}
                >
                    {this.handler.text}
                </alt-badge>
            );
        } else {
            return <div />;
        }
    }
}
