











import { Vue, Component, Prop } from "vue-property-decorator";
import { BPagination } from "bootstrap-vue";

@Component({
    name: "alt-pagination",
    components: { BPagination },
})
export default class AltPagination extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: Number })
    private total!: number;

    @Prop({ type: Number })
    private limit!: number;

    @Prop({ type: Number })
    private value!: number;

    public get page(): number {
        return this.value;
    }

    public set page(page: number) {
        this.$emit("input", page);
    }

    public change(event: any) {
        this.$emit("change", event);
    }
}
