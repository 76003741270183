import { Vue, Component, Watch } from "vue-property-decorator";
import {
    Currency,
    IAccount,
    ICompany,
    IEmployee,
    IReadQuery,
    ISelectQuery,
    ISettingsTableUpdateDto,
    ITable,
    ITransactionTransferDto,
    IUser,
} from "@lib";
import { ITransaction, ITransactionItem, TransactionType } from "@lib";
import { ITransactionCreateDto, ITransactionDeleteManyDto } from "@lib";
import { ITableFilter, ITableSort, TableType } from "@lib";
import { PermissionType, PermissionRight } from "@lib";
import AltTable, { TableApi } from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { Button, Icon, Label } from "@core/components/alt-ui/controls";
import { MultiDropdown } from "@core/controls/multi-dropdown/multi-dropdown";
import { TransactionModal, TransactionModalContext } from "./transaction.modal";
import { getDefaultTableColumns, getDefaultTableActions } from "./accounts-defaults";
import AccountsToolbar from "./accounts-toolbar/accounts-toolbar.vue";
import { TransferModal, TransferModalContext } from "./accounts-transfer.modal";
import { AccountsFilterController, IAccountsFilterContext } from "./accounts-filter-controller";
import { Formatter } from "@/utils/formatter";

@Component({
    name: "accounts",
    components: { AltTable, AccountsToolbar, ModalComponent },
})
export default class Accounts extends Vue {
    private TransactionUseCase = this.$alt.system.usecase.createTransactionUseCase();
    private TransactionItemUseCase = this.$alt.system.usecase.createTransactionItemUseCase();
    private SettingsTable = this.$alt.system.usecase.createSettingsTableUseCase();

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private accounts: IAccount[] = [];
    private selectedAccounts: IAccount[] = [];

    private transactions: ITransaction[] = [];
    private transactionsTotal: number = 0;
    private transactionItems: ITransactionItem[] = [];

    private employees: IEmployee[] = [];

    private table: any = {
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",
    };

    private transactionModal: TransactionModal;
    private transferModal: TransferModal;

    public filterController: AccountsFilterController;

    public get filterContext(): IAccountsFilterContext {
        return {
            accounts: this.accounts,
            employees: this.employees,
            transactionItems: this.transactionItems,

            defaultFilter: {
                account: this.selectedAccounts.map(e => e.id),
            },
            currentFilter: this.filter,
        };
    }

    public constructor() {
        super();

        this.transactionModal = new TransactionModal();
        this.transactionModal.onCreate = this.create.bind(this);
        this.transactionModal.onTransferClick = this.openModalTransfer.bind(this);

        this.transferModal = new TransferModal();
        this.transferModal.onCreate = this.createTransfer.bind(this);

        this.filterController = new AccountsFilterController();
        this.filterController.onSave = this.saveFilter.bind(this);
    }

    private get tableType(): TableType {
        return TableType.Transaction;
    }

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this, this.transactionItems);
    }

    private get defaulActions(): any[] {
        return getDefaultTableActions(this);
    }

    private get transactionsData(): ITransaction[] {
        return this.transactions ? this.transactions : [];
    }

    private get transactionsDataTotal(): number {
        return this.transactionsTotal ? this.transactionsTotal : 0;
    }

    private get can(): any {
        const secure = this.$secure;
        return {
            get update(): Function {
                return (transaction: any): boolean => {
                    return false; //secure.check(PermissionType.Account, transaction.account.id, PermissionRights.TransactionsUpdate);
                };
            },
            get delete(): Function {
                return (transaction: any): boolean => {
                    return secure.check(
                        PermissionType.Accounts,
                        transaction.account.id,
                        PermissionRight.TransactionsDelete,
                    );
                };
            },
        };
    }

    private get columns(): any[] {
        return TableApi.prepareColumns(this.defaulColumns, this.table.settings.columns);
    }

    private get limit(): number {
        return this.table.settings.limit ?? TableApi.DefaultLimit;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings.sort ?? [];
    }

    private get filter(): ITableFilter[] {
        return this.table.settings.filter ?? [];
    }

    @Watch("selectedAccounts", { deep: true, immediate: true })
    private onSelectedAccountsUpdate(): void {
        const headerControls = this.$info.ui.getHeaderControls();

        const hdrAccounts = headerControls.find(c => c.id === "accounts.header-accounts") as MultiDropdown<IAccount>;
        if (hdrAccounts) {
            if (!this.$alt.compareArrays(hdrAccounts.selectedItems, this.selectedAccounts, e => e.id)) {
                hdrAccounts.selectedItems = this.selectedAccounts;
            }
        }

        const lblBalance = headerControls.find(c => c.id === "accounts.header-balance") as Label;
        if (lblBalance) {
            lblBalance.text = this.getBalanceText(this.selectedAccounts);
        }
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.employees = await this.$info.getEmployees();
            this.accounts = await this.$info.getAccounts();
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        await Promise.all([this.selectTableSettings(), this.selectTransactionItems()]);

        const filterAccounts = this.filter.filter(item => item.field === "account");
        this.selectedAccounts = this.accounts.filter(a => !!filterAccounts.find(f => f.value === a.id));

        this.filterController.init(this.filterContext);

        if (!this.selectedAccounts.length && this.accounts[0]) {
            this.filterController.filter.account.set([this.accounts[0].id]);

            this.saveFilter(this.filterController.tableFilter);
        }

        this.initHeader();

        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrAccounts = new MultiDropdown<IAccount>();
        hdrAccounts.id = "accounts.header-accounts";
        hdrAccounts.items = this.accounts;
        hdrAccounts.selectedItems = this.selectedAccounts;
        hdrAccounts.itemId = item => item.id;
        hdrAccounts.itemName = item => item.info.name;
        hdrAccounts.itemDescription = item =>
            Formatter.money(item.balance, {
                currency: item.info.currency,
                locale: item.info.locale,
            });
        hdrAccounts.iconPackage = "feather";
        hdrAccounts.icon = "CreditCardIcon";
        hdrAccounts.locale = {
            Tooltip: "Выбрать счёт",
            ButtonSelectOne: "Выбрать один",
            ButtonSelectMultiple: "Выбрать несколько",
            ButtonSelectAll: "Выбрать все",
            TextNotSelected: "Счёт не выбран",
            TextSelectedAll: "Все счета",
            TextSelectedMultiple: "Выбрано:",
            TextSelectedMultipleForms: ["счёт", "счёта", "счетов"],
        };
        hdrAccounts.addChangedHandler((s, e) => {
            this.filterController.filter.account.set(e.items.map(account => account.id));
            this.saveFilter(this.filterController.tableFilter);
        });

        const lblBalance = new Label();
        lblBalance.id = "accounts.header-balance";
        lblBalance.class = "h2 mb-0";
        lblBalance.text = this.getBalanceText(this.selectedAccounts);
        lblBalance.visible = this.selectedAccounts.length > 0;

        const hdrRefresh = new Button();
        hdrRefresh.id = "accounts.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrAccounts, lblBalance, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.t) {
                if (this.$route.query.t === "income") {
                    this.openModalCreate(TransactionType.Income);
                    return;
                }
                if (this.$route.query.t === "expense") {
                    this.openModalCreate(TransactionType.Expense);
                    return;
                }
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            throw new Error("Транзакция не найдена.");
        }
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectTableSettings(): Promise<void> {
        let settings: ITable | null = null;
        try {
            settings = await this.SettingsTable.get(this.company.id, this.user.id, this.tableType);
        } catch {}
        this.applyTableSettings(settings ?? this.table.settings);
    }

    private applyTableSettings(settings: ITable | null): void {
        if (!settings) {
            throw new Error();
        }

        this.table.settings = settings;

        if (settings.filter && settings.filter.length > 0) {
            return;
        }
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            // добавить к запросу номер страницы: p=2
            // if (page === 1) super.queryRemove("p");
            // else super.queryAdd("p", page);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private openModalCreate(type: TransactionType): void {
        const context: TransactionModalContext = {
            type: type,
            accounts: this.accounts,
            selectedAccounts: this.selectedAccounts,
            items: this.transactionItems,
            userId: this.user.id,
        };
        this.transactionModal.show(context);
    }

    private openModalTransfer(): void {
        const context: TransferModalContext = {
            selectedAccounts: this.selectedAccounts,
            accounts: this.accounts,
        };

        this.transferModal.show(context);
    }

    private async confirmDelete(transaction: any): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить транзакцию: "${transaction.description}"?`,
            "Удаление транзакции",
            { okText: "Удалить" },
        );

        if (result) {
            await this.delete(transaction);
        }
    }

    private async confirmDeleteMany(transactions: ITransaction[]): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить ${transactions.length} транзакций?`,
            "Удаление транзакций",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.deleteMany(transactions);
        }
    }

    public showFilter(): void {
        this.filterController.show(this.filterContext);
    }

    private async saveFilter(filter: ITableFilter[]): Promise<boolean> {
        try {
            const dto: ISettingsTableUpdateDto = { filter: filter };
            const service = this.$alt.system.usecase.createSettingsTableUseCase();
            await service.update(this.company.id, this.user.id, TableType.Transaction, dto);

            this.changeFilter(filter);

            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить настройки фильтра:\n${e.message}`);
            return false;
        }
    }

    private async changeFilter(filter: ITableFilter[], withDataUpdate = true): Promise<void> {
        try {
            this.table.settings.filter = filter;

            const filterAccounts = this.filter.filter(item => item.field === "account");
            this.selectedAccounts = this.accounts.filter(a => !!filterAccounts.find(f => f.value === a.id));

            if (withDataUpdate) {
                await this.selectData(this.skip, this.limit, this.sort, this.filter);
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async selectAccounts(): Promise<void> {
        try {
            this.accounts = await this.selectAccountsForUser();

            const filterAccounts = this.filter.filter(item => item.field === "account");
            this.selectedAccounts = this.accounts.filter(a => !!filterAccounts.find(f => f.value === a.id));
        } catch (e: any) {
            throw new Error(`Не удалось загрузить счета:\n${e.message}`);
        }
    }

    private async selectAccountsForUser(): Promise<IAccount[]> {
        const accounts = await this.$alt.system.usecase
            .createEmployeeUseCase()
            .selectAccounts(this.company.id, this.user.id);
        this.$info.setAccounts(accounts);
        return accounts;
    }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            // обновлять данные счетов (в том числе баланс)
            await this.selectAccounts();

            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: IReadQuery = { limit, offset, sort, search, filter };
            const result = await this.TransactionUseCase.select(this.company.id, query);

            this.transactions = result.data;
            this.transactionsTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить транзакции:\n${e.message}`);
        }
    }

    private async selectTransactionItems(): Promise<void> {
        try {
            this.transactionItems = await this.TransactionItemUseCase.select(this.company.id);
            //console.log(this.transactionItems);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить типы транзакций:\n${e.message}`);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.transactions = [];
            this.transactionsTotal = 0;
        }
    }

    private async create(dto: ITransactionCreateDto): Promise<ITransaction | null> {
        try {
            this.$alt.loader.show();
            const tr = await this.TransactionUseCase.create(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Транзакция успешно создана.");
            return tr;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async createTransfer(dto: ITransactionTransferDto): Promise<ITransaction[] | null> {
        try {
            this.$alt.loader.show();

            const tr = await this.TransactionUseCase.transfer(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Перевод между счетами успешно совершён.");

            return tr;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(transaction: ITransaction): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.TransactionUseCase.delete(this.company.id, transaction.id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Транзакция успешно удалена.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deleteMany(transactions: ITransaction[]): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: ITransactionDeleteManyDto = {
                ids: transactions.map(t => t.id),
            };
            await this.TransactionUseCase.deleteMany(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Транзакции успешно удалены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private getBalanceText(accounts: IAccount[]): string {
        let summary = "";
        const currencies: any = {};

        for (const account of accounts) {
            if (!currencies[account.info.currency]) {
                currencies[account.info.currency] = { sum: 0, locale: account.info.locale };
            }

            currencies[account.info.currency].sum += account.balance;
        }

        for (const currency in currencies) {
            if (summary.length > 0) {
                summary += "; ";
            }

            summary += Formatter.money(currencies[currency].sum, {
                currency: currency as Currency,
                locale: currencies[currency].locale,
            });
        }

        return summary;
    }
}
