import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { AltCheckBox } from "@/core/alt-ui";
import { CheckBox } from "./check-box";
import { SwitchBox } from "./switch-box";

@Component({
    name: "check-box-component",
    components: { AltCheckBox, ValidationProvider },
})
export class CheckBoxComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: CheckBox | SwitchBox;

    public get validation(): string | null {
        // чтобы проверять, стоит ли галочка, нужно установить правило: rules="required:true"
        if (this.handler.validation?.includes("required") && !this.handler.validation?.includes("required:true")) {
            return this.handler.validation.replace("required", "required:true");
        }

        return this.handler.validation;
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.text}
                        mode="eager"
                        rules={this.validation}
                        vid={this.handler.id ?? undefined}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        items.push(
            <alt-check-box
                id={this.handler.id}
                class={this.handler.class}
                style={this.handler.style}
                switch={this.handler instanceof SwitchBox ? true : false}
                help={this.handler.help}
                disabled={this.handler.disabled}
                state={errors.length > 0 ? "error" : null}
                subtext={errors[0]}
                subtext-class="text-danger"
                v-model={this.handler.value}
            >
                {this.handler.text}
            </alt-check-box>,
        );

        return items;
    }
}
