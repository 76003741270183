import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BDropdown, BDropdownItem, BTooltip } from "bootstrap-vue";
import { Dropdown } from "./dropdown";
import { IconComponent } from "./icon.component";

@Component({
    name: "dropdown-component",
    components: { BDropdown, BDropdownItem, BTooltip, IconComponent },
})
export class DropdownComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: Dropdown;

    public clickItem(item: any): void {
        this.handler.clickItem(item);
    }

    public render(): VNode {
        if (this.handler.visible) {
            if (this.handler.help && this.handler.help.length > 0) {
                return (
                    <div>
                        {this.renderDropdown()}

                        <b-tooltip target={this.handler.id} triggers="hover" placement="bottom">
                            {this.handler.help}
                        </b-tooltip>
                    </div>
                );
            }

            return this.renderDropdown();
        } else {
            return <div />;
        }
    }

    private renderDropdown(): VNode {
        return (
            <b-dropdown
                id={this.handler.id}
                variant={this.handler.variant}
                class={`${this.handler.class} ${this.handler.hideCaret && "dropdown-icon-wrapper"}`}
                style={this.handler.style}
                disabled={this.handler.disabled}
                scopedSlots={{ "button-content": () => this.renderContent() }}
            >
                {this.renderItems()}
            </b-dropdown>
        );
    }

    private renderContent(): VNode[] {
        const content: VNode[] = [];
        if (this.handler.icon) {
            const margin = this.handler.text.length > 0 ? "mr-0.5" : "";
            content.push(<icon-component handler={this.handler.icon} class={`h-1 w-1 ${margin}`} />);
        }
        content.push(<span>{this.handler.text}</span>);
        return content;
    }

    private renderItems(): VNode[] {
        return this.handler?.items.map(item => (
            <b-dropdown-item v-on:click={() => this.clickItem(item)}>
                {this.handler.iconField ? (
                    <feather-icon icon={this.handler.iconField(item)} class="h-1 w-1 mr-0.5" />
                ) : null}
                {this.handler.textField ? this.handler.textField(item) : ""}
            </b-dropdown-item>
        ));
    }
}
