import { Button, Control } from "../controls";

export class ButtonGroup extends Control {
    public size: "sm" | "md" | "lg" = "md";

    private _buttons: Button[] = [];

    public get buttons(): Button[] {
        return this._buttons;
    }

    public getComponentName(): string {
        return "ButtonGroupComponent";
    }

    public clearButtons(): void {
        this._buttons = [];
    }

    public addButton(button: Button): void {
        this._buttons.push(button);
    }

    public addButtons(buttons: Button[]): void {
        this._buttons.push(...buttons);
    }
}
