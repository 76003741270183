import { ITableColumn } from "@lib";
import { Sidebar } from "@core/components/alt-ui/sidebar";
import { Button, Control } from "@core/components/alt-ui/controls";
import { IAltTableToolboxContext, AltTableToolboxControl } from "./alt-table-toolbox-control";

export type { IAltTableToolboxContext, IAltTableToolboxExport } from "./alt-table-toolbox-control";

export interface IToolboxOptions {
    columns?: boolean;
    export?: string[];
}

export interface IAltTableToolboxResult {
    columns: ITableColumn[];
}

export class AltTableToolbox extends Sidebar<IAltTableToolboxContext, IAltTableToolboxResult> {
    private tableControl!: AltTableToolboxControl;

    private btnSave!: Button;
    private btnCancel!: Button;

    private context?: IAltTableToolboxContext;

    public constructor() {
        super("alt-table-toolbox", "НАСТРОЙКИ ТАБЛИЦЫ");
        this.initializeControls();
    }

    public show(context: IAltTableToolboxContext): Promise<IAltTableToolboxResult> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.tableControl = new AltTableToolboxControl();

        if (this.context) {
            this.tableControl.initialize({
                columns: this.context.columns,
                export: this.context.export,
                tableApi: this.context.tableApi,
            });
        }

        //

        this.btnSave = new Button();
        this.btnSave.id = "alt-table-toolbox.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.class = "mr-0.75";
        this.btnSave.variant = "success";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.btnCancel = new Button();
        this.btnCancel.id = "alt-table-toolbox.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.variant = "flat-danger";
        this.btnCancel.addClickHandler(() => this.hide());
    }

    public get controls(): Control[] {
        return [this.tableControl];
    }

    public get footerControls(): Control[] {
        return [this.btnSave, this.btnCancel];
    }

    // Нужно для обработки сворачивания
    public hide(): void {
        this.tableControl.reset();
        super.hide();
    }

    private async clickSave(): Promise<void> {
        const settings = this.tableControl.getSettings();
        super.hide({
            columns: settings.columns,
        });
    }
}
