import { IOrderTypeFieldGroup } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    ComboBoxChangedEventArgs,
    Control,
    IconPackage,
    Panel,
    Select,
    TextBox,
} from "@core/components/alt-ui/controls";

export interface IGroupFieldModalContext {
    group?: IOrderTypeFieldGroup;
}

type AltIcon = {
    icon: string;
    name: string;
};

const altIconOptions: AltIcon[] = [
    { icon: "info", name: "Информация" },
    { icon: "user", name: "Клиент" },
    { icon: "list", name: "Список" },
    { icon: "document", name: "Документ" },
    { icon: "clipboard", name: "Документ" },
    { icon: "monitor", name: "Устройство" },
    { icon: "smartphone", name: "Смартфон" },
    { icon: "washing-machine", name: "Стиральная машина" },
    { icon: "car", name: "Автомобиль" },
    { icon: "house", name: "Дом" },
    { icon: "scissors", name: "Ножницы" },
    { icon: "pants", name: "Штаны" },
    { icon: "shoes", name: "Обувь" },
    { icon: "bank-card", name: "Оплата" },
    { icon: "finance", name: "Оплата" },
];

export class GroupFieldModal extends Modal<IGroupFieldModalContext, IOrderTypeFieldGroup> {
    private tbName!: TextBox;
    private cbIcon!: Select<AltIcon>;
    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: IGroupFieldModalContext | null = null;

    public constructor() {
        super("group-field-modal", "");
        this.initializeControls();
    }

    public show(context: IGroupFieldModalContext): Promise<IOrderTypeFieldGroup> {
        this.context = context;
        this.initializeControls();

        if (context.group) {
            this.title = "Изменение группы";
            this.btnSave.text = "Изменить";
            this.populateControls(context.group);
        } else {
            this.title = "Новая группа";
            this.btnSave.text = "Добавить";
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbName = new TextBox();
        this.tbName.id = "group-field.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.cbIcon = new Select<AltIcon>();
        this.cbIcon.id = "group-field.icon";
        this.cbIcon.label = "Иконка";
        this.cbIcon.iconPackage = IconPackage.Alt;
        this.cbIcon.icon = this.cbIcon.selectedItem?.icon ?? null;
        this.cbIcon.items = altIconOptions;
        this.cbIcon.textField = a => ""; //a.name;
        this.cbIcon.iconField = a => a.icon;
        this.cbIcon.addChangedHandler((s: any, e: ComboBoxChangedEventArgs<AltIcon>) => {
            this.cbIcon.icon = e.item?.icon ?? null;
        });
        this.cbIcon.selectedIndex = 0;
        this.cbIcon.class = "w-25%";

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "group-field.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "group-field.save";
        this.btnSave.text = "Добавить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(group: IOrderTypeFieldGroup): void {
        this.tbName.text = group.name ?? "";
        this.cbIcon.selectedIndex = altIconOptions.findIndex(o => o.icon === group.icon);
    }

    public get controls(): Control[] {
        return [this.tbName, this.cbIcon, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        const group = this.context?.group ? this.edit(this.context.group) : this.add();
        if (!group) {
            return;
        }

        this.hide(group);
    }

    private add(): IOrderTypeFieldGroup | null {
        // TODO
        return null;
    }

    private edit(orig: IOrderTypeFieldGroup): IOrderTypeFieldGroup | null {
        if (!this.context) {
            return null;
        }

        orig.name = this.tbName.text;
        orig.icon = this.cbIcon.selectedItem?.icon;

        return orig;
    }
}
