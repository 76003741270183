import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
    BFormCheckbox,
    VBTooltip,
    BDropdown,
    BDropdownGroup,
    BDropdownHeader,
    BDropdownItem,
    BDropdownText,
    BDropdownDivider,
} from "bootstrap-vue";
import AltIcon from "@/core/components/alt-icon";
import { StringUtils } from "@/utils/string.utils";
import { MultiDropdown } from "./multi-dropdown";

@Component({
    name: "multi-dropdown-component",
    components: {
        BFormCheckbox,
        BDropdown,
        BDropdownGroup,
        BDropdownHeader,
        BDropdownItem,
        BDropdownText,
        BDropdownDivider,
        AltIcon,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class MultiDropdownComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: MultiDropdown;

    private multipleSelectionMode = false;

    private resourcesDefault = {
        Tooltip: "Выбрать",

        ButtonSelectOne: "Выбрать один",
        ButtonSelectMultiple: "Выбрать несколько",
        ButtonSelectAll: "Выбрать все",

        TextNotSelected: "Не выбрано",
        TextSelectedAll: "Все",
        TextSelectedMultiple: "Выбрано:",
        TextSelectedMultipleForms: ["элемент", "элемента", "элементов"],
    };

    private get resources(): any {
        return { ...this.resourcesDefault, ...this.handler.locale };
    }

    private getSelectedItemsText(): string {
        if (this.handler.selectedItems.length === 0) {
            return this.resources.TextNotSelected;
        }

        if (this.handler.selectedItems.length === 1) {
            return this.handler.itemTitle
                ? this.handler.itemTitle(this.handler.selectedItems[0])
                : this.handler.itemName(this.handler.selectedItems[0]);
        }

        if (this.handler.selectedItems.length === this.handler.items.length) {
            return this.resources.TextSelectedAll;
        }

        const form = StringUtils.decline(this.handler.selectedItems.length, this.resources.TextSelectedMultipleForms);
        return `${this.resources.TextSelectedMultiple} ${this.handler.selectedItems.length} ${form}`;
    }

    private isSelected(item: any): boolean {
        return !!this.handler.selectedItems.find(i => this.handler.itemId(i) === this.handler.itemId(item));
    }

    private selectOne(item: any): void {
        this.handler.selectedItems = [item];
    }

    private selectMultiple(): void {
        //
    }

    private selectAll(): void {
        this.handler.selectedItems = [...this.handler.items];
    }

    private toggleMultiple(): void {
        this.multipleSelectionMode = !this.multipleSelectionMode;
        if (this.multipleSelectionMode) {
            //
        }
    }
}
