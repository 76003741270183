import { StringPair } from "@/core/types";
import { ITableFilter, ReadQueryFilter, TableFilterOperator } from "@lib";

export type FilterPair = [string, string];

export enum FilterType {
    Between = "between",
    In = "in",
    Equals = "notIn",
}

// TODO: переместить в другую папку - подумать, куда лучше

export abstract class Filter {
    public static convertEqualsBack(key: string, value: string): ITableFilter[] {
        const filter: ITableFilter[] = [];

        if (value) {
            filter.push({
                field: key,
                operator: "eq",
                value: value,
            });
        }

        return filter;
    }

    public static convertInBack(key: string, values: string | string[]): ITableFilter[] {
        const filter: ITableFilter[] = [];

        if (!Array.isArray(values)) {
            values = [values];
        }

        for (const value of values) {
            if (value.length > 0) {
                filter.push({
                    field: key,
                    operator: TableFilterOperator.In,
                    value: value,
                });
            }
        }

        return filter;
    }

    public static convertBetweenBack(key: string, value: FilterPair): ITableFilter[] {
        const filter: ITableFilter[] = [];

        if (value[0] && value[0].length > 0) {
            filter.push({
                field: key,
                operator: TableFilterOperator.Gte,
                value: value[0],
            });
        }

        if (value[1] && value[1].length > 0) {
            filter.push({
                field: key,
                operator: TableFilterOperator.Lte,
                value: value[1],
            });
        }

        return filter;
    }

    //

    public static convertEquals(key: string, tblfilter: ITableFilter[], $default = ""): string {
        for (const tf of tblfilter) {
            // Filter expr ("quantity[lte]" = "$quantityThreshold")
            if (tf.value.length > 0 && tf.value[0] === "$") {
                continue;
            }

            if (tf.field === key && tf.operator === "eq") {
                return tf.value;
            }
        }
        return $default;
    }

    public static convertIn(key: string, tblfilter: ITableFilter[]): string[] {
        const values: string[] = [];
        for (const tf of tblfilter) {
            if (tf.field !== key) {
                continue;
            }

            // Filter expr ("quantity[lte]" = "$quantityThreshold")
            if (tf.value.length > 0 && tf.value[0] === "$") {
                continue;
            }

            if (tf.operator === TableFilterOperator.In || tf.operator === TableFilterOperator.Equals) {
                values.push(tf.value);
            }
        }
        return values;
    }

    public static convertBetween(key: string, tblfilter: ITableFilter[]): FilterPair {
        const pair: FilterPair = ["", ""];
        for (const tf of tblfilter) {
            // Filter expr ("quantity[lte]" = "$quantityThreshold")
            if (tf.value.length > 0 && tf.value[0] === "$") {
                continue;
            }

            if (tf.field === key && tf.operator === TableFilterOperator.Gte) {
                pair[0] = tf.value;
            }
            if (tf.field === key && tf.operator === TableFilterOperator.Lte) {
                pair[1] = tf.value;
            }
        }
        return pair;
    }

    //

    public static inToSelectQueryFilter(key: string, values: string[]): Record<string, any> {
        return { [`${key}[in]`]: values };
    }

    public static betweenToSelectQueryFilter(key: string, value: StringPair): Record<string, any> {
        const filter: Record<string, any> = {};

        if (value[0] && value[0].length > 0) {
            filter[`${key}[gte]`] = value[0];
        }

        if (value[1] && value[1].length > 0) {
            filter[`${key}[lte]`] = value[1];
        }

        return filter;
    }

    public static inToReadQueryFilter(key: string, values: string[]): ReadQueryFilter {
        return { [key]: { in: values } };
    }

    public static betweenToReadQueryFilter(key: string, value: StringPair): ReadQueryFilter {
        const filter: ReadQueryFilter = {};

        if (value[0] && value[0].length > 0) {
            if (!filter[key]) {
                filter[key] = {};
            }

            (filter[key] as any)["gte"] = value[0];
        }

        if (value[1] && value[1].length > 0) {
            if (!filter[key]) {
                filter[key] = {};
            }

            (filter[key] as any)["lte"] = value[1];
        }

        return filter;
    }
}
