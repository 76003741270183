






import { Vue, Component, Prop } from "vue-property-decorator";
import { BAlert } from "bootstrap-vue";

@Component({
    name: "alt-alert",
    components: { BAlert },
})
export default class AltAlert extends Vue {
    @Prop({ type: String })
    private id!: string;

    @Prop({ type: String, default: "primary" })
    private variant!: string;
}
