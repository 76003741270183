import { IDiscount, DiscountType, Locale, Currency } from "@lib";
import { ISelectOption } from "../../@core/types/common/select-options";
import { moneyFormat } from "@/filters/money";
import { Localizer } from "@/i18n/localizer";

export type DiscountArray = [string, DiscountType];

export abstract class DiscountUtils {
    public static getTypes(): DiscountType[] {
        return [DiscountType.Percent, DiscountType.Fixed];
    }

    public static getTypeText(type: DiscountType, options?: { currency?: Currency }): string {
        switch (type) {
            case DiscountType.Percent:
                return "%";
            case DiscountType.Fixed:
                return options?.currency ? Localizer.currencySymbol(options.currency) : "фикс.";
        }
    }

    public static getOptions(): ISelectOption<DiscountType>[] {
        return DiscountUtils.getTypes().map(t => ({
            id: t,
            name: DiscountUtils.getTypeText(t),
        }));
    }

    public static toString(discount: IDiscount | undefined, locale: Locale = Locale.RU): string {
        if (!discount) {
            return "";
        }

        const options = DiscountUtils.getOptions();
        const option = options.find(o => o.id === discount.type);

        if (!option) {
            return "";
        }

        if (discount.type === DiscountType.Percent) {
            return `${discount.value}%`;
        }

        return moneyFormat(discount.value, { locale });
    }

    public static fromArray(array: DiscountArray): IDiscount | undefined {
        if (array[0].length === 0) {
            return undefined;
        }

        const tmp = array[0].trim().replace(",", ".");
        const value = parseFloat(tmp);

        if (!value) {
            return undefined;
        }

        return { value, type: array[1] };
    }

    public static toArray(discount: IDiscount | null, locale: Locale = Locale.RU): DiscountArray {
        if (!discount) {
            return DiscountUtils.getEmptyArray();
        }

        const value = Localizer.number(discount.value, locale);
        return [value, discount.type];
    }

    public static getEmptyArray(): DiscountArray {
        return ["0", DiscountType.Percent];
    }
}
