import { VNode } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { FieldType } from "@lib";
import { AltDateTime } from "@/core/alt-ui";
import { DateTime } from "./date-time";
import { Formatter } from "@/utils/formatter";

@Component({
    name: "date-time-component",
    components: { AltDateTime, ValidationProvider },
})
export class DateTimeComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: DateTime;

    private get value(): string {
        if (!this.handler.value) {
            return "";
        }

        return Formatter.datetime(this.handler.value, { format: this.getFormat() });
    }

    private set value(value: string) {
        this.handler.value = value ? new Date(value) : null;
    }

    private getFormat(): string {
        if (this.handler.mode === FieldType.Date) {
            return "YYYY-MM-DD";
        }

        if (this.handler.mode === FieldType.Time) {
            return "HH:mm";
        }

        return "YYYY-MM-DD HH:mm";
    }

    public render(): VNode {
        if (this.handler.visible) {
            return (
                <div class={this.handler.class} style={this.handler.style}>
                    <validation-provider
                        //class="form-label-group m-0"
                        class="m-0"
                        name={this.handler.name ?? this.handler.label ?? this.handler.placeholder}
                        mode="eager"
                        rules={this.handler.validation}
                        vid={this.handler.id}
                        scopedSlots={{
                            default: (validation: any) => this.renderInput(validation),
                        }}
                    ></validation-provider>
                </div>
            );
        } else {
            return <div />;
        }
    }

    private renderInput(validation: any): VNode[] {
        const items: VNode[] = [];
        const errors = validation.errors;

        items.push(
            <alt-date-time
                id={this.handler.id}
                label={this.handler.label}
                placeholder={this.handler.placeholder}
                help={this.handler.help}
                disabled={this.handler.disabled}
                state={errors.length > 0 ? "error" : null}
                subtext={errors[0]}
                subtext-class="text-danger"
                mode={this.handler.mode}
                v-model={this.value}
            />,
        );

        return items;
    }
}
