























import { Vue, Component, Prop } from "vue-property-decorator";
import { BCard, BCardHeader, BCardBody, BCollapse } from "bootstrap-vue";
import { Uuid } from "@/utils/uuid";

@Component({
    name: "alt-collapse-item",
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCollapse,
    },
})
export default class AltCollapseItem extends Vue {
    @Prop({ type: Boolean, default: false })
    public isVisible!: boolean;

    @Prop({ type: String, required: true })
    public title!: string;

    public visible = false;
    public collapseItemID = "";
    public openOnHover: boolean | undefined = this.parentHover;

    private get parentHover(): boolean | undefined {
        return (this.$parent as any).hover;
    }

    private get parentAccordion(): boolean | undefined {
        return (this.$parent as any).accordion;
    }

    private get parentCollapseID(): string | undefined {
        return (this.$parent as any).collapseID;
    }

    public get accordion(): string | null {
        return this.parentAccordion ? `accordion-${this.parentCollapseID}` : null;
    }

    public created(): void {
        this.collapseItemID = Uuid.new();
        this.visible = this.isVisible;
    }

    public updateVisible(val = true): void {
        this.visible = val;
        this.$emit("visible", val);
    }

    public collapseOpen(): void {
        if (this.openOnHover) {
            this.updateVisible(true);
        }
    }

    public collapseClose(): void {
        if (this.openOnHover) {
            this.updateVisible(false);
        }
    }
}
