














import { Vue, Component, Prop } from "vue-property-decorator";
import { IGood, ISelectedData } from "@lib";
import AltSearchGoods from "@/core/components/alt-search-goods/alt-search-goods.vue";
import { SearchGoods } from "./search-goods";

@Component({
    components: {
        AltSearchGoods,
    },
})
export default class SearchGoodsComponent extends Vue {
    @Prop({ type: Object })
    private handler!: SearchGoods;

    public searchFunc(search: string): Promise<ISelectedData<IGood>> {
        if (!this.handler.searchFunc) {
            return Promise.resolve({ data: [], total: 0 } as ISelectedData<IGood>);
        }

        return this.handler.searchFunc(search);
    }

    public selectItem(item: IGood): void {
        this.handler.notifySelectItemHandler(item);
    }
}
