

































import { Vue, Component } from "vue-property-decorator";
import { VBTooltip } from "bootstrap-vue";
import AltIcon from "@/core/components/alt-icon";

@Component({
    name: "CellRendererDynamic",
    components: { AltIcon },
    directives: { "b-tooltip": VBTooltip },
})
export default class CellRendererDynamic extends Vue {
    private params: any;

    private get elements(): any {
        return this.params.elements;
    }
}
